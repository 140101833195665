import React, { useEffect, useState } from 'react';
import { VideoSectionContainer, TextSection, H2, P, StyledIframe } from './VideoSectionStyles';
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../Firebase";
import { doc, collection, getDocs } from "firebase/firestore";

function VideoSection() {
  const [videoId, setVideoId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoId = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(db, "LandingConfiguration", "x3aWKMi87yl6nMO0RtLh");

      // Referencia a la colección anidada "Videos"
      const videosCollectionRef = collection(landingConfigDocRef, "Videos");

      const videosSnapshot = await getDocs(videosCollectionRef);

      if (!videosSnapshot.empty) {
        // Tomando el primer documento de la colección "Videos" como ejemplo.
        // Puedes adaptar esto según tus necesidades.
        const videoData = videosSnapshot.docs[0].data();
        setVideoId(videoData.videoId);
      } else {
        console.error("No se encontró el videoId en la colección anidada.");
      }

      setLoading(false);
    };

    fetchVideoId();
  }, []);

  const videoSrc = videoId ? `https://www.youtube.com/embed/${videoId}` : null;

  return (
    <VideoSectionContainer>
      <TextSection>
        <H2>All Law Enforcements need to watch this Video!</H2>
        <P>Training at North Side Brazilian Jiu-Jitsu can save your life and save the lives of your loved ones. All law enforcement officers should try it out for a FREE week and see why it is so benefitial for each and everyone of you!</P>
      </TextSection>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <StyledIframe
          src={videoSrc}
          title="YouTube Video"
          frameborder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></StyledIframe>
      )}
    </VideoSectionContainer>
  );
}

export default VideoSection;
