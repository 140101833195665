import styled from "styled-components";
import Calendar from "react-calendar";

export const CalendarContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;
  padding: 20px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    padding: 0px;
    margin-left: 5%;
    width: 100vw;
  }

`;

// Styling for the left and right navigation buttons
export const NavigationButton = styled.button`
  background-color: #b3b3b3; // Replace with your desired background color
  color: #cc0000; // Replace with your desired text color
  border: none;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    background-color: #737373; // Replace with your desired hover background color
    color: #990000; // Replace with your desired hover text color
  }
`;

export const StyledCalendar = styled(Calendar)`
  background-color: #000; // Changed to pure black
  color: #fff; // Ensuring all text is white
  border-radius: 5px;
  border: 0;

  // Para centrar el calendario en la página
  width: 100%;
  height: 100%;
  padding: 20px;
  max-width: 800px; // Puedes ajustar según la preferencia

  // Estilizar el encabezado (mes y año)
  .react-calendar__navigation__label {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

  // Estilizar el hover de la barra de navegación del calendario
  .react-calendar__navigation button:hover {
    background-color: #333333; // Reemplaza #tuColorOscuro con el color que desees
  }

  // Estilizar los nombres de los días de la semana
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none; // Eliminar el subrayado
    color: #ff0b0b;
  }

  .react-calendar__tile {
    color: #fff;
    height: 50px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #414141;
  }

  // Personalizar los días activos (seleccionados)
  .react-calendar__tile--active {
    background-color: #ff0b0b;
    background: #ff0b0b;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:focus {
    background-color: #ff0b0b;

    color: #fff;
    outline: none; // Esto quitará el borde azul alrededor del elemento focuseado
  }

  // Personalizar los días al pasar el mouse por encima
  .react-calendar__tile:hover {
    background-color: #616161;
  }

  // Styling for the left navigation button
  .react-calendar__navigation__prev-button {
    ${NavigationButton}// Additional styling if needed
  }

  // Styling for the right navigation button
  .react-calendar__navigation__next-button {
    ${NavigationButton}// Additional styling if needed
  }

  .react-calendar__tile--now {
    background-color: #006edc;
    color: white; // Texto blanco para el contraste
  }

  .react-calendar__tile--now:hover {
    background-color: #d10000;
  }

  // Ocultar los botones de navegación anual
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

//   /* Override calendar text color */
// .rbc-calendar {
//   color: white;
// }

// /* Override specific text, such as headers, labels */
// .rbc-header,
// .rbc-label {
//   color: white;
// }

// /* Override selection or highlight color */
// .rbc-selected {
//   background-color: red;
// }


  @media (max-width: 768px) {
    width: 90%; // Ocupar más ancho en dispositivos pequeños
    margin: 20px auto; // Reducir el margen

    // Ajustar el tamaño del texto del encabezado (mes y año) para pantallas pequeñas
    .react-calendar__navigation__label {
      font-size: 18px;
    }

    // Ajustar el tamaño de las casillas de fecha
    .react-calendar__tile {
      height: 40px;
    }
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: rgba(51, 51, 51, 1);
  min-width: 160px;
  z-index: 101;
  background-color: rgba(51, 51, 51, 1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  top: 50%; /* Ajusta según tus necesidades */
  left: 50%; /* Ajusta según tus necesidades */
  transform: translate(-50%, -50%);
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px 0px;
  width: 100%;
  height: 50px;

  &:hover ${DropdownContent} {
    display: block;
  }
`;

export const StyledSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  color: white;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
`;

export const StyledOption = styled.option`
  background-color: rgba(51, 51, 51, 1);
  color: #fff;
`;

export const FullContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  margin: 0;
  color: #fff;
  margin-bottom: 10px;
`;

export const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 10px;
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; // Default background
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  ${ToggleInput}:checked + & {
    background-color: #d10000; // Red background when toggle is checked
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #d10000; // Optional: Red shadow on focus
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(26px); // Move the slider to the right when checked
  }
`;


export default StyledCalendar;



