// src/Pages/NextStepsPage.js
import React from "react";
import PresentationNextSteps from "../Components/Presentations/PresentationNextSteps";
import Footer from "../Components/Footer/Footer";


function NextSteps() {
  return (
    <div>
      <PresentationNextSteps />
      <Footer />

    </div>
  );
}

export default NextSteps;
