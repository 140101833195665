import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";


import {
  FormContainer,
  FormImage,
  FormImageImg,
  FormFields,
  FormField,
  FormH2,
  FormLabel,
  FormInput,
  FlexCheckboxContainer,
  StyledLink,
  ButtonContainer,
  FormButton,
  ThankYouMessage,
  ThankYouContainer,
  AdditionalMessage,
  RedirectMessage,
  CheckmarkIcon,
  Wrapper,
  Label,
} from "./FormStyles";

import CircularProgress from "@mui/material/CircularProgress";
import { db, storage } from "../../Firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import SignatureCanvas from "react-signature-canvas";

function Form() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [secondsRemaining, setSecondsRemaining] = useState(5);
  const [formData, setFormData] = useState({
    image: "",
    header: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    agreedToLiability: false,
    signatureUrl: "",
  });

  const sigCanvas = useRef(null); // Usar useRef aquí

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [waiverUrl, setWaiverUrl] = useState("");

  const getPDFUrl = async () => {
    const docRef = doc(db, "academies", "x3aWKMi87yl6nMO0RtLh"); // Asume que es la ID del documento que deseas acceder
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().waiverPDF; // Aquí obtiene el campo "waiverPDF" del documento
      } else {
        console.error("Document not found");
      }
    } catch (error) {
      console.error("Error obteniendo URL del PDF: ", error);
    }
  };

  useEffect(() => {
    const fetchWaiverUrl = async () => {
      const url = await getPDFUrl();
      setWaiverUrl(url);
    };

    fetchWaiverUrl();
  }, []);

  const springProps = useSpring({
    to: {
      maxHeight: formData.agreedToLiability ? "350px" : "0px",
      opacity: formData.agreedToLiability ? 1 : 0,
      marginBottom: formData.agreedToLiability ? "20px" : "0px", // Agrega margen inferior
    },
    from: {
      maxHeight: "0px",
      opacity: 0,
      marginBottom: "0px", // Inicializa margen inferior
    },
    overflow: "hidden",
    config: { tension: 200, friction: 20 },
  });

  const [showSignature, setShowSignature] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setFormData((prevState) => ({
      ...prevState,
      agreedToLiability: checked,
    }));
    setShowSignature(checked); // Cambiar la visibilidad de la firma
  };

  const createUserWithDetails = async (signatureURL) => {
    try {
      await addDoc(collection(db, "users"), {
        ...formData,
        signatureUrl: signatureURL,
        timestamp: serverTimestamp(), // Agrega la marca de tiempo del lado del servidor
      });
    } catch (error) {
      throw new Error(`Error creating user: ${error.message}`);
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.phone &&
      formData.agreedToLiability
    ) {
      setLoading(true);
      try {
        if (!formData.signatureUrl && sigCanvas.current) {
          const signatureData = sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png");
          const signatureBlob = dataURLToBlob(signatureData);
          const signatureRef = ref(storage, `waivers/${formData.email}.png`);
          const snapshot = await uploadBytes(signatureRef, signatureBlob);
          const signatureURL = await getDownloadURL(snapshot.ref);
          formData.signatureUrl = signatureURL;
        }
        await addDoc(collection(db, "users"), {
          ...formData,
          timestamp: serverTimestamp(),
        });
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error at uploading data: ", error);
        setErrorMessage("Error submitting the form.");
      } finally {
        setLoading(false);
      }

      window.location.href = '/next-steps'; //redirect to the next steps view
    } else {
      setErrorMessage(
        "Please fill all the fields, sign, and agree to the waiver before submitting."
      );
    }
  };

  useEffect(() => {
    let timer;

    if (isSubmitted && secondsRemaining > 0) {
      timer = setInterval(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);
    } else if (isSubmitted && secondsRemaining === 0) {
      clearInterval(timer);
      window.location.reload();
    }

    return () => clearInterval(timer);
  }, [isSubmitted, secondsRemaining]);

  const [formContent, setFormContent] = useState({
    header: "",
    image: "",
  });

  useEffect(() => {
    const fetchFormContent = async () => {
      const docRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh",
        "FormKids",
        "wULjMh3KsBvMhkf0qkIM"
      );
      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormContent(docSnap.data()); // Actualiza el estado con los datos obtenidos
        } else {
          console.log("Document not found");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchFormContent();
  }, []);

  const weightOptions = [
    "Under 40 lbs",
    "40-60 lbs",
    "60-80 lbs",
    "80-100 lbs",
    "100-120 lbs",
    "Over 120 lbs"
  ];
  
  const heightOptions = [
    "Under 3'5\"",
    "3'5\" - 4'0\"",
    "4'0\" - 4'6\"",
    "4'6\" - 5'0\"",
    "5'0\" - 5'5\"",
    "Over 5'5\""
  ];
  
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Wrapper>
      <FormContainer>
        {isSubmitted ? (
          <ThankYouContainer>
            <ThankYouMessage>Thank You</ThankYouMessage>
            <AdditionalMessage>
              Your Request will be processed Shortly
            </AdditionalMessage>
            <RedirectMessage>
              You will be redirected in {secondsRemaining} seconds...
            </RedirectMessage>
            <CheckmarkIcon />
          </ThankYouContainer>
        ) : (
          <>
            <FormImage>
              <FormImageImg src={formContent.image} alt="Form Image" />
            </FormImage>
            <form onSubmit={handleFormSubmit}>
              <FormFields>
                <FormH2>
                  Sign up your child for a free week of Brazilian Jiu-Jitsu
                </FormH2>
                <FormField>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <FormInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="Enter your child's first name"
                  />
                </FormField>

                <FormField>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <FormInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Enter your child's last name"
                  />
                </FormField>
                <FormField>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <FormInput
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                  />
                </FormField>

                <FormField>
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <FormInput
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter your phone number"
                  />
                </FormField>

                {/* Campo para altura */}
                <FormField style={{ marginBottom: "10px" }}>
                  <FormLabel htmlFor="child-height">Child's Height</FormLabel>
                  <select id="child-height" name="child-height">
                    {heightOptions.map((height, index) => (
                      <option key={index} value={height}>
                        {height}
                      </option>
                    ))}
                  </select>
                </FormField>

                {/* Campo para peso */}
                <FormField style={{ marginBottom: "20px" }}>
                  <FormLabel htmlFor="child-weight">Child's Weight </FormLabel>
                  <select id="child-weight" name="child-weight">
                    {weightOptions.map((weight, index) => (
                      <option key={index} value={weight}>
                        {weight}
                      </option>
                    ))}
                  </select>
                </FormField>

                <FormField>
                  <FlexCheckboxContainer>
                    <input
                      type="checkbox"
                      id="agreed-to-liability"
                      name="agreedToLiability"
                      checked={formData.agreedToLiability}
                      onChange={handleCheckboxChange}
                    />
                    <FormLabel
                      htmlFor="agreed-to-liability"
                      style={{ marginLeft: "10px" }}
                    >
                      I have read and agreed to the{" "}
                      <StyledLink to="/waiver">
                        release and liability agreement
                      </StyledLink>
                    </FormLabel>
                  </FlexCheckboxContainer>
                </FormField>

                <animated.div style={{ ...springProps, width: '100%', borderRadius: '10px' }}>
                  <Label>Sign Here</Label>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      className: "signature-canvas",
                      style: { backgroundColor: "white", width: '100%', height: '200px', borderRadius: '20px' } // Added borderRadius here
                    }}
                    ref={sigCanvas}
                  />
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <FormButton type="button" onClick={clearSignature}>
                      Clear Signature
                    </FormButton>
                  </div>
                </animated.div>

                <ButtonContainer>
                  <FormButton
                    type="submit"
                    disabled={!formData.agreedToLiability}
                    style={{
                      backgroundColor: formData.agreedToLiability
                        ? "red"
                        : "grey",
                      cursor: formData.agreedToLiability
                        ? "pointer"
                        : "not-allowed",
                    }}
                  >
                    Submit
                  </FormButton>
                </ButtonContainer>
              </FormFields>

              {errorMessage && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </form>
          </>
        )}
      </FormContainer>
    </Wrapper>
  );
}

function dataURLToBlob(dataURL) {
  const parts = dataURL.split(";base64,");
  const imageType = parts[0].split(":")[1];
  const decodedData = window.atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: imageType });
}

export default Form;
