import styled from 'styled-components';

export const DayContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
   max-width: 630px; // Fix the width of the entire container
    margin: auto; // Center the container

`;

// DayViewStyles.js updates

export const DayRow = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr; // Updated width to 80px
  align-items: stretch;
  grid-gap: 0px;
  width: 100%;
  min-height: 60px;
`;

export const TimeBlock = styled.div`
  border: 1px solid white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch; // Ensure it stretches to fill the row height
`;

export const ClassBlock = styled.div`
  display: flex;
  flex-direction: row; // Updated to row to align items horizontally
  flex-wrap: wrap; // Allows items to wrap to the next line
  border: 1px solid white;
  padding: 5px;
  box-sizing: border-box;
  align-self: stretch;
`;


// Keep TimeBracket and EventItem as they are.

export const TimeBracket = styled.div`
  color: white;
  width: 100%; /* Same width as TimeBlock */
  height: 100%; /* Same height as TimeBlock */
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const ClassItem = styled.div`
  background-color: red; /* Customize your event bubble color */
  color: white;
  padding: 10px;
  margin: 5px ;
  flex: 0 0 auto;
  border-radius: 15px;
  cursor: pointer;
`;

export const EventItem = styled.div`
  background-color: white; /* Customize your event bubble color */
  color: black;
  padding: 10px;
  margin: 5px ;
  flex: 0 0 auto;
  border-radius: 15px;
  cursor: pointer;
`;

export const InstructorLine = styled.div`
  font-size: 0.8em; // Smaller font size for the instructor UID
  color: #fff; // White color for text
  margin-top: 4px; // Space from the main text
`;
