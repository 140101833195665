import React from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDay } from 'date-fns';
import { MonthContainer, DayCell, EventMarker } from './MonthViewStyles'; // Adjust the import path as needed

const MonthView = ({ date }) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  const days = eachDayOfInterval({ start, end });

  // Fill start and end gaps
  const startPadding = Array(getDay(start)).fill(null);
  const totalCells = startPadding.length + days.length; // Adjust if you want a fixed 6 rows
  const endPadding = Array(6 * 7 - totalCells).fill(null);

  return (
    <div>
      <h2>{format(date, 'MMMM yyyy')}</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '10px' }}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day}>{day}</div>
        ))}
        {startPadding.map((_, index) => (
          <div key={`start-pad-${index}`} />
        ))}
        {days.map(day => (
          <div key={day}>
            {format(day, 'd')}
            {/* Map your events/classes here */}
          </div>
        ))}
        {endPadding.map((_, index) => (
          <div key={`end-pad-${index}`} />
        ))}
      </div>
    </div>
  );
};

export default MonthView;
