import styled from 'styled-components';

export const PricingDetailsContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
`;

export const Highlight = styled.span`
  font-weight: bold;
  color: #d85e56;
`;

export const Button = styled.button`
  display: inline-block;
  padding: 10px 20px;
  background-color: #d85e56;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background-color: #c24d46;
  }
`;