import React from 'react';
import { GraduationContainer, GraduationImage } from './IBJJFGraduationStyles';

const IBJJFGraduation = ({ imageUrl }) => (
  <GraduationContainer>
    <GraduationImage src={imageUrl} alt="IBJJF Graduation System" />
  </GraduationContainer>
);

export default IBJJFGraduation;
