import React from "react";
import PresentationAres from "../Components/Presentations/PresentationAres";
import Footer from "../Components/Footer/Footer";
import Gallery from "../Components/Gallery/Gallery";

function AresAffiliate() {
  return (
    <div>
      <PresentationAres />

      <Gallery collectionName={"AresGallery"}/>

      <Footer />
    </div>
  );
}

export default AresAffiliate;
