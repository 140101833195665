import React, { useEffect, useState } from 'react';
import { FooterContainer, FooterLogo, FooterText } from './FooterStyles';
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../Firebase";  // Importa tu archivo de Firebase correctamente
import { doc, getDoc } from "firebase/firestore";

function Footer() {
  const [academyData, setAcademyData] = useState({
    logo: '',
    name: '',
    address: '',
    cityState: '',
    phone: '',
  });
  
  const [loading, setLoading] = useState(true);  // Estado para el loading

  useEffect(() => {
    const fetchAcademyData = async () => {
      const docRef = doc(db, "academies", "x3aWKMi87yl6nMO0RtLh");  // Reemplaza 'yourDocumentId' con el ID del documento que quieres acceder
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAcademyData(docSnap.data());
      } else {
        console.error("Documento no encontrado");
      }
      
      setLoading(false);  // Cambiamos el estado de loading a false cuando los datos se han cargado
    };

    fetchAcademyData();
  }, []);

  return (
    <FooterContainer>
      {loading ? (  // Mostramos el CircularProgress si está en estado de loading
        <CircularProgress />
      ) : (
        <>
          <FooterLogo
            src={academyData.logo}
            alt={academyData.name}
          />
          <FooterText>
            <p>{academyData.name}</p>
            <p>{academyData.address}</p>
            <p>{academyData.cityState}</p>
            <br></br>
            <p>{academyData.phone}</p>
          </FooterText>
        </>
      )}
    </FooterContainer>
  );
}

export default Footer;