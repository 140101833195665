import styled from "styled-components";
import Slider from "react-slick";

export const AdultsContent = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5%;

  @media (max-width: 768px) {
    margin: 3%;
  }
`;

export const AdultsTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  margin: 50px;
  border-radius: 10px;
  text-align: justify;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 30px;
  }

  @media (max-width: 480px) {
    margin: 15px;
  }
`;

export const AdultsTitle = styled.div`
  font-size: 36px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 15%;
  }
`;

export const AdultsTitleP = styled.p`
  margin: 0;
  font-size: 110px;

  @media (max-width: 768px) {
    font-size: 80px;
  }

  @media (max-width: 480px) {
    font-size: 60px;
  }
`;

export const AdultsSubtext = styled.div`
  font-size: 60px;
  margin-top: 10px;
  font-family: "Asap Condensed", sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
  }
`;

export const AdultsText = styled.div`
  font-size: 18px;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Card = styled.div`
  width: 300px;
  height: 550px; // Altura fija para la tarjeta
  overflow-y: auto; // Permite desplazamiento vertical si el contenido es demasiado largo
  margin: 20px;
  background-color: #222222;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 240px;
    height: 400px; // Tamaño reducido para pantallas más pequeñas
  }

  @media (max-width: 480px) {
    width: 180px;
    height: 300px; // Tamaño aún más reducido para pantallas muy pequeñas
  }

  /* Estilos para el scrollbar */
  &::-webkit-scrollbar {
    width: 5px; // Ancho de la barra de desplazamiento
  }

  &::-webkit-scrollbar-track {
    background: transparent; // Fondo de la barra de desplazamiento
  }

  &::-webkit-scrollbar-thumb {
    background: #888; // Color del thumb de la barra de desplazamiento
    border-radius: 10px; // Bordes redondeados
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; // Color al pasar el mouse por encima
  }
`;

export const CardContainer = styled.div`
  display: flex;
`;

export const CardImage = styled.img`
  width: 100%; // Ancho total del contenedor de la tarjeta
  height: 200px; // Altura fija para todas las imágenes
  object-fit: cover; // Ajusta la imagen para cubrir el espacio disponible, recortando si es necesario
  margin-bottom: 10px; // Mantiene el margen inferior
  object-position: top;
`;

export const CardTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: white;
  padding-left: 20px;
  margin-bottom: 0px;
`;

export const CardText = styled.p`
  font-size: 20px;
  font-weight: 300;
  padding: 20px;
  color: white;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ScheduleText = styled.p`
  font-size: 16px;
  font-weight: 300;
  padding-left: 20px;
  color: white;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const DayText = styled.strong`
  color: white;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  padding-left: 20px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const Carousel = styled(Slider)`
  width: 80%; // Ancho para 3 tarjetas
  overflow: hidden; // Oculta las tarjetas que exceden el ancho
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-bottom: 30px; // Este es el nuevo espacio que dejará lugar para los puntos

  .slick-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: none;
    font-size: 80px;
    cursor: pointer;
    left: 0;
    z-index: 1;

    @media (max-width: 768px) {
      font-size: 60px;
    }

    @media (max-width: 480px) {
      font-size: 40px; // reducción adicional para pantallas muy pequeñas
    }
  }

  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: none;
    font-size: 80px;
    cursor: pointer;
    right: 0;
    z-index: 1;

    @media (max-width: 768px) {
      font-size: 60px;
    }

    @media (max-width: 480px) {
      font-size: 40px; // reducción adicional para pantallas muy pequeñas
    }
  }

  .slick-dots {
    width: 100%; // Asegura que el contenedor de puntos tome todo el ancho del carrusel
    position: absolute;
    bottom: 0; // Los puntos se colocan en la parte inferior del carrusel
    display: flex;
    justify-content: center; // Centra los puntos horizontalmente dentro del contenedor
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 5;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
    background-color: red; /* Usamos un color brillante para identificarlos */
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .slick-dots li.slick-active {
    background-color: white; /* Color de fondo del punto activo */
  }
`;
