import React from "react";
import AboutUs from "../Components/AboutUs/AboutUs";
import Gallery from "../Components/Gallery/Gallery";
import WhiteBanner from "../Components/WhiteBanner/WhiteBanner";
import Presentation from "../Components/Presentations/Presentation";
import VideoSection from "../Components/VideoSection/VideoSection";
import Footer from "../Components/Footer/Footer";
import GoogleMapComponent from "../Components/GoogleMapsComponent/GoogleMapsComponent";

function Home() {
  return (
    <div>
      <Presentation />

      <AboutUs />

      <WhiteBanner />

      <Gallery collectionName={"HomeGallery"} />

      <VideoSection />

      <GoogleMapComponent />

      <Footer />
    </div>
  );
}
export default Home;
