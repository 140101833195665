import styled from 'styled-components';

export const WhiteBannerContainer = styled.div`
  background-color: white;
  padding: 20px;
  height: auto; // Auto-ajustar la altura según el contenido
  text-align: center;
  position: relative;

  @media (max-width: 768px) { 
    padding: 10px; // Reducir el padding en móviles
  }
`;

export const WhiteBannerTitle = styled.h1`
  margin-top: 40px;
  font-size: 36px;
  color: black;
  font-weight: 400;

  @media (max-width: 768px) { 
    font-size: 26px; // Reducir la fuente en móviles
    margin-top: 20px; // Reducir el margen superior en móviles
  }
`;

export const WhiteBannerParagraph = styled.p`
  font-size: 20px;
  color: #333;
  margin-inline: 24%;

  @media (max-width: 768px) { 
    font-size: 14px; // Reducir la fuente en móviles
    margin-inline: 10%; // Reducir los márgenes laterales en móviles
  }
`;