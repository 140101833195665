import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import { db } from "../Firebase"; // Make sure this import is correct
import { doc, getDoc } from "firebase/firestore";
import { Column, Text } from "../Components/UtilsStyles";
import Footer from "../Components/Footer/Footer"; // Import your Footer component
import {
  InfoText,
  RedText,
  Title,
} from "../Components/Presentations/PresentationMerchStyles";
import ImageWithFixedHeight from "../Components/ImageWithFixedHeight";
import styled from "styled-components";

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: #333;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &.slick-prev {
    left: 10px;
  }

  &.slick-next {
    right: 10px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: #555;
    transform: translateY(-50%) scale(1.1);
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 70%;
  max-height: 100%;
  margin: auto;
  object-fit: cover;
  display: block;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  width: ${props => props.width}px;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  margin: 5px;
  cursor: pointer;
  border: ${props => props.isActive ? '2px solid red' : 'none'};
`;

const NavButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoContainer = styled(Column)`
  flex: 1;
  @media (min-width: 768px) {
    margin-left: 20px;
  }
`;

const DescriptionText = styled(Text)`
  white-space: pre-line;
  
`;
function ProductInfo() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mainImage, setMainImage] = useState("");
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const thumbnailsPerPage = 4;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const landingConfigDocRef = doc(db, "store", "x3aWKMi87yl6nMO0RtLh");
        const productDocRef = doc(landingConfigDocRef, "products", id);
        const productSnapshot = await getDoc(productDocRef);
        const productData = productSnapshot.data();
        setProduct(productData);
        setMainImage(productData.images[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) return <Loading />;

  if (!product)
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          minHeight: "100vh",
        }}
      >
        Product not found
      </h1>
    );

  const handlePrevClick = () => {
    setThumbnailIndex(Math.max(thumbnailIndex - thumbnailsPerPage, 0));
  };

  const handleNextClick = () => {
    setThumbnailIndex(Math.min(thumbnailIndex + thumbnailsPerPage, product.images.length - thumbnailsPerPage));
  };

  return (
    <>
    <ProductContainer>
      <ImageContainer>
        <CarouselImage src={mainImage} alt={product.name} />
        <ThumbnailContainer>
          {thumbnailIndex > 0 && <NavButton className="prev" onClick={handlePrevClick}>&lt;</NavButton>}
          <ThumbnailWrapper width={thumbnailsPerPage * 110} style={{ transform: `translateX(-${thumbnailIndex * 110}px)` }}>
            {product.images.map((image, index) => (
              <Thumbnail
                key={index}
                src={image}
                alt={`${product.name} ${index + 1}`}
                onClick={() => setMainImage(image)}
                isActive={mainImage === image}
              />
            ))}
          </ThumbnailWrapper>
          {thumbnailIndex < product.images.length - thumbnailsPerPage && <NavButton className="next" onClick={handleNextClick}>&gt;</NavButton>}
        </ThumbnailContainer>
      </ImageContainer>
      <InfoContainer>
        <RedText  >
          {product.name}
        </RedText >

        
        <br />
        <DescriptionText>{product.description}</DescriptionText>
        <br />
        <br />
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <ImageWithFixedHeight
              src="/Google_Play_Store_badge_EN.png"
              alt="Available in Google Play"
              fixedHeight={70}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/north-side-bjj/id6476119325"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageWithFixedHeight
              src="/app_store_badge.png"
              alt="Available in the App Store"
              fixedHeight={70}
            />
          </a>
        </div>
        <Title>
          <InfoText small={true} color="gray">
            To buy NSBJJ products you can do it from our app on the
            following platforms
          </InfoText>
        </Title>
      </InfoContainer>
    </ProductContainer>
      <Footer />
      </>
  );
}

export default ProductInfo;
