import styled from "styled-components";

export const CardWrapper = styled.div`
  background: linear-gradient(to right, #d85e56, #d22929);
  color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
  width: calc(33.33% - 20px);
  min-width: 250px;
  margin: 20px;
  height: 400px;
  position: relative;

  @media (max-width: 1024px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: top; // Ajusta el enfoque de la imagen
  transition: transform 0.6s ease-in-out;
  transform: translateY(0);

  ${CardWrapper}:hover & {
    transform: translateY(-85%);
  }
`;

export const CardName = styled.h3`
  font-size: 18px;
  font-weight: 400;
  font-family: "Alatsi", sans-serif;
  color: white;
  position: relative;
  bottom: 15px; // Esta posición inicial debería colocar el CardName justo debajo de la imagen
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition: transform 0.6s ease-in-out, bottom 0.6s ease-in-out;

  ${CardWrapper}:hover & {
    bottom: 73%;
    transform: translateX(-50%) translateY(-50%); // El translateY(-50%) centra el CardName verticalmente durante el hover
  }
`;

export const CardSubtitle = styled.h4`
  font-size: 14px; // Ajusta según prefieras
  font-weight: 300;
  font-family: "Be Vietnam", sans-serif;
  color: white;
  position: relative;
  bottom: 30px; // Ajusta según la distancia que quieras respecto a CardName
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.6s ease-in-out, bottom 0.6s ease-in-out,
    opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;

  ${CardWrapper}:hover & {
    bottom: 77%; // Ajusta este valor para que el subtítulo se mueva de forma adecuada durante el hover
    transform: translateX(-50%) translateY(-50%);
    opacity: 1; // Al hacer hover, se muestra el subtítulo
    visibility: visible;
  }
`;

export const CardText = styled.p`
  margin: 10px;
  padding: 20px;

  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  color: white;

  // Usa opacity en lugar de display para ocultar/mostrar el texto
  opacity: 0;
  pointer-events: none; // Asegura que el texto no interfiera con eventos del ratón cuando está oculto
  z-index: -1; // Posiciona el texto detrás de otros elementos

  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transform: translateY(400px);

  ${CardWrapper}:hover & {
    opacity: 1;
    z-index: 1; // Trae el texto al frente cuando se muestra
    transform: translateY(18%);
  }
`;

export const HoverContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(216, 94, 86, 0.9),
    rgba(210, 41, 41, 0.9)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Inicialmente transparente */
  transition: opacity 0.6s ease-in-out; /* Transición de opacidad suave */
  pointer-events: none; /* Evita que el contenido sea interactivo antes de hacer hover */

  & ${CardText} {
    // Especifica el color del texto dentro de HoverContent
    color: white;
  }

  ${CardWrapper}:hover & {
    opacity: 1; /* Muestra el contenido al hacer hover en la tarjeta */
  }
`;
