import React from "react";
import {
  StepsContent,
  StepsTextContainer,
  StepsTitle,
  TitleP,
  StepsSubtext,
  StepsText,
  StepsList,
  StepsListItemStyle,
  StepsListItem,
  Link,
  NumberedStep,
  BulletList
} from "./PresentationNextStepsStyles";
import Footer from "../Footer/Footer";
import ImageWithFixedHeight from "../ImageWithFixedHeight";

function PresentationNextSteps() {
  return (
    <StepsContent>
      <StepsTextContainer>
        <StepsTitle>
          <TitleP><span className="red-text">You are all set!</span></TitleP>
        </StepsTitle>
        <StepsSubtext>Welcome to our <span className="red-text">NSBJJ</span> academy! Here are your next steps to get started:</StepsSubtext>
      </StepsTextContainer>

      <StepsTextContainer>
        <NumberedStep>1. <strong>Download the Mobile App:</strong></NumberedStep>
        <BulletList>
          <li>You can download our mobile app <Link href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">here</Link> for Android or <Link href="https://apps.apple.com/us/app/north-side-bjj/id6476119325" target="_blank" rel="noopener noreferrer">here</Link> for iOS.</li>
          <li>An account has already been created for you. Please sign in using your registered email.</li>
        </BulletList>

        <NumberedStep>2. <strong>Set Up Your Password:</strong></NumberedStep>
        <BulletList>
          <li>Click on "Forgot Password" in the app to set up your password.</li>
          <li>Check your email to create a password.</li>
        </BulletList>

        <NumberedStep>3. <strong>Access the App:</strong></NumberedStep>
        <BulletList>
          <li>Once logged in, you will have access to the daily schedule and your QR code for check-in.</li>
          <li>Simply show up for the class you are interested in and scan your QR code on the tablet when you arrive.</li>
        </BulletList>

        <NumberedStep>4. <strong>What to Wear:</strong></NumberedStep>
        <BulletList>
          <li>Comfortable clothing is fine for your first class.</li>
        </BulletList>

        <NumberedStep>5. <strong>Read the House Rules:</strong></NumberedStep>
        <BulletList>
          <li>Make sure to read our <Link href="/house-rules">house rules</Link> before coming to train.</li>
        </BulletList>

        <NumberedStep>6. <strong>On Your First Visit:</strong></NumberedStep>
        <BulletList>
          <li>Let the professor know that you are new to the academy.</li>
        </BulletList>

        <StepsText>We look forward to seeing you on the mats!</StepsText>
      </StepsTextContainer>

      <div
        style={{
          textAlign: "left",
          marginTop: "20px",
          marginBottom: "20px",
          marginLeft: "20px",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
          <ImageWithFixedHeight
            src="/Google_Play_Store_badge_EN.png"
            alt="Available in Google Play"
            fixedHeight={70}
          />
        </a>
        <a href="https://apps.apple.com/us/app/north-side-bjj/id6476119325" target="_blank" rel="noopener noreferrer">
          <ImageWithFixedHeight
            src="/app_store_badge.png"
            alt="Available in the App Store"
            fixedHeight={70}
          />
        </a>
      </div>

    </StepsContent>
  );
}

export default PresentationNextSteps;
