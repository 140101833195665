import styled from "styled-components";

export const KidsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin: 5%;

  @media (max-width: 768px) {
    margin: 5%;
  }
`;

export const KidsTextContainer = styled.div`
  flex: 1;
  margin: 50px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 25px 15px;
  }
`;

export const KidsTitle = styled.div`
  font-size: 24px;
  line-height: 1.1;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TitleP = styled.p`
  margin: 0;
  font-size: 100px;

  @media (max-width: 768px) {
    font-size: 60px;
    margin-top: 15%;
  }
`;

export const KidsSubtext = styled.div`
  font-size: 60px;
  margin-top: 10px;
  font-family: "Asap Condensed", sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const KidsText = styled.div`
  p {
    font-size: 24px;
    font-weight: 300;
    text-align: justify;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const KidsList = styled.ul`
  list-style-type: disc; // Puedes cambiar a otro estilo si lo prefieres
  padding-left: 20px;
  margin: 20px 0;
`;

export const KidsListItemStyle = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const KidsListItem = styled.li`
  font-size: 20px;
  font-weight: 300;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
