import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Importa íconos de flecha
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  AdultsContent,
  AdultsTextContainer,
  AdultsTitle,
  AdultsTitleP,
  AdultsSubtext,
  Carousel,
  Card,
  CardContainer,
  CardImage,
  CardTitle,
  CardText,
  ScheduleText,
  DayText,
} from "./PresentationAdultsStyles";

import { db } from "../../Firebase";
import { collection, doc, getDocs } from "firebase/firestore";

function PresentationAdults() {
  const [cards, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Agrega estado para manejar errores

  useEffect(() => {
    const fetchAdultsData = async () => {
      try {
        // Referencia al documento específico en "LandingConfiguration"
        const landingConfigDocRef = doc(
          db,
          "LandingConfiguration",
          "x3aWKMi87yl6nMO0RtLh"
        );

        // Referencia a la colección anidada "AdultClasses"
        const adultClassesCollectionRef = collection(
          landingConfigDocRef,
          "AdultClasses"
        );

        const adultClassesSnapshot = await getDocs(adultClassesCollectionRef);
        const adultClassesList = adultClassesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          schedule: doc.data().schedule || {}, // Asegúrate de que 'schedule' esté incluido
        }));

        // Ordena el array basado en el campo "order"
        const sortedAdultClassesList = adultClassesList.sort(
          (a, b) => a.order - b.order
        );

        setData(sortedAdultClassesList);
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setError(error); // Maneja errores
        setLoading(false);
      }
    };

    fetchAdultsData();
  }, []);

  const settings = {
    infinite: false,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <FaArrowLeft aria-label="Previous" />, // Usa íconos de flecha con etiqueta aria
    nextArrow: <FaArrowRight aria-label="Next" />,
    variableWidth: true,
    centerMode: true,
    centerPadding: "30px",
    dots: true,
    appendDots: (dots) => <ul>{dots}</ul>,
  };

  const DAYS_OF_WEEK = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return (
    <AdultsContent>
      <AdultsTextContainer>
        <AdultsTitle>
          <AdultsTitleP>
            <span className="red-text">ADULT</span> Classes
          </AdultsTitleP>
        </AdultsTitle>
        <div>
          <AdultsSubtext>North Side Adults BJJ Class</AdultsSubtext>
        </div>
      </AdultsTextContainer>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : error ? (
        <div>Error al cargar los datos.</div>
      ) : (
        <Carousel {...settings}>
          {cards.map((card) => (
            <CardContainer key={card.id}>
              <Card>
                <CardImage src={card.image} alt={card.title} loading="lazy" />
                <CardTitle>{card.title}</CardTitle>
                <CardText>{card.description}</CardText>
                <div>
                  {Object.entries(card.schedule)
                    .sort(
                      (a, b) =>
                        DAYS_OF_WEEK.indexOf(a[0]) - DAYS_OF_WEEK.indexOf(b[0])
                    )
                    .map(([day, dayInfo]) => (
                      <div key={day}>
                        <DayText>{day}</DayText>
                        {dayInfo.classes.map((classItem, index) => (
                          <ScheduleText key={index}>
                            {classItem.time} - {classItem.className} (
                            {classItem.instructor})
                          </ScheduleText>
                        ))}
                      </div>
                    ))}
                </div>
              </Card>
            </CardContainer>
          ))}
        </Carousel>
      )}
    </AdultsContent>
  );
}

export default PresentationAdults;
