import React, { useState, useEffect } from 'react';
import {
  AresContent,
  AresTextContainer,
  AresTitle,
  TitleP,
  AresSubtext,
  AresText,
} from './PresentationAresStyles';
import { db } from "../../Firebase"; // Asegúrate de importar tu archivo de Firebase correctamente
import { doc, getDocs, collection } from 'firebase/firestore';

function PresentationAres() {
  const [data, setData] = useState({
    title: '',
    highlightedText: '',
    subtext: '',
    paragraphs: []
  });

  useEffect(() => {
    const fetchAresData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(db, "LandingConfiguration", "x3aWKMi87yl6nMO0RtLh");

      // Referencia a la colección anidada "Ares"
      const AresCollectionRef = collection(landingConfigDocRef, "Ares");

      const AresSnapshot = await getDocs(AresCollectionRef);

      // Asumiendo que solo hay un documento en la colección "Ares"
      if (!AresSnapshot.empty) {
        const AresData = AresSnapshot.docs[0].data();
        setData({
          titleRed: AresData.titleRed,
          title: AresData.title,
          subtext: AresData.subtext,
          paragraphs: AresData.paragraphs
        });
      } else {
        console.error("Documento no encontrado en Ares");
      }
    };

    fetchAresData();
  }, []);

  return (
    <AresContent>
      <AresTextContainer>
        <AresTitle>
          <TitleP>
            <span className="red-text">{data.titleRed}</span> {data.title}
          </TitleP>
        </AresTitle>
        <AresSubtext>
          {data.subtext}
        </AresSubtext>
        <AresText>
          {data.paragraphs.map((paragraph, index) => (
            <p key={index}>
              {paragraph.split('<br />').map((frag, idx, array) => (
                <React.Fragment key={idx}>
                  {frag}
                  {idx !== array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          ))}
        </AresText>
      </AresTextContainer>
    </AresContent>
  );
}

export default PresentationAres;
