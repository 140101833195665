import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 0;
  margin: 20px;
  margin-right: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 30%;
  min-height: 300px;
  color: white;
  background: linear-gradient(to right, #d85e56, #d22929);
  border: 5px solid white;


  @media (max-width: 768px) {
    width: 60%;
    padding: 20px;
    margin-right: 15px;
    min-height: 250px;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin-right: 5px;
    padding: 15px;
    min-height: 200px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 15px;
  margin-top: 0;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  width: 50%;

  @media (max-width: 768px) {
    font-size: 24px;
    width: 70%;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    width: 100%;
  }
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  padding-left: 20px;
  text-align: justify;
  justify-content: center;
  font-size: 20px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;

  li {
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
