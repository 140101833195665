import styled from "styled-components";

export const StepsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin: 5%;

  @media (max-width: 768px) {
    margin: 5%;
  }
`;

export const StepsTextContainer = styled.div`
  flex: 1;
  margin: 50px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 25px 15px;
  }
`;

export const StepsTitle = styled.div`
  font-size: 24px;
  line-height: 1.1;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TitleP = styled.p`
  margin: 0;
  font-size: 100px;
  color: white;

  .red-text {
    color: red;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    margin-top: 15%;
  }
`;

export const StepsSubtext = styled.div`
  font-size: 60px;
  margin-top: 10px;
  font-family: "Asap Condensed", sans-serif;
  font-style: italic;

  .red-text {
    color: red;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const StepsText = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StepsList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin: 20px 0;
`;

export const StepsListItemStyle = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StepsListItem = styled.li`
  font-size: 20px;
  font-weight: 300;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Link = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const NumberedStep = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 40px;
  margin: 10px 0 20px 0;
`;
