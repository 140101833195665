import React from "react";
import {
  StrikingContent,
  StrikingTextContainer,
  StrikingTitle,
  StrikingTitleP,
  StrikingSubtext,
} from "./PresentationStrikingStyles";

function PresentationStriking() {
  return (
    <StrikingContent>
      <StrikingTextContainer>
        <StrikingTitle>
          <StrikingTitleP>
            <span className="red-text">Striking</span> Instructors
          </StrikingTitleP>
        </StrikingTitle>
        <StrikingSubtext>
          Masters of Attack
        </StrikingSubtext>
      </StrikingTextContainer>
    </StrikingContent>
  );
}

export default PresentationStriking;
