import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px; // Reduce el padding en dispositivos móviles
  }
`;

export const FooterLogo = styled.img`
  width: 200px;
  height: 200px;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100px;    // Reduce el tamaño del logo en dispositivos móviles
    height: 100px;   // Reduce el tamaño del logo en dispositivos móviles
    margin-right: 10px;  // Reduce el margen derecho en dispositivos móviles
  }
`;

export const FooterText = styled.div`
  color: white;
  line-height: 0.6;
  text-align: justify;
  font-family: Be Vietnam;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;  // Reduce el tamaño de fuente en dispositivos móviles
  }
`;
