import React from "react";
import Slider from "react-slick";
import {
  CardContainer,
  ProductImage,
  ProductName,
  ProductPrice,
  ProductDescription,
  ProductColors,
  ProductSizes,
} from "./ProductCardStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

// Estilos personalizados para las flechas
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: red;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &.slick-prev {
    left: 10px;
  }

  &.slick-next {
    right: 10px;
  }

  
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: #555;  // Cambiar el fondo al hacer hover
    transform: translateY(-50%) scale(1.1);  // Ampliar ligeramente el tamaño al hacer hover
  }
`;

const CustomPrevArrow = (props) => (
  <Arrow {...props} className="slick-prev">
    &lt;
  </Arrow>
);

const CustomNextArrow = (props) => (
  <Arrow {...props} className="slick-next">
    &gt;
  </Arrow>
);

function ProductCard({ product }) {
  const renderColors = () => {
    if (product.colors && product.colors.length > 0) {
      return (
        <ProductColors>
          <strong>Colors:</strong> {product.colors.join(", ")}
        </ProductColors>
      );
    }
    return null;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <CardContainer href={`/product/${product.id}`}>
      {product.images && product.images.length > 0 ? (
        <Slider {...settings}>
          {product.images.map((image, index) => (
            <div key={index}>
              <ProductImage
                src={image}
                alt={product.name}
                style={{ minHeight: "150px" }} // Ajusta el tamaño mínimo aquí
              />
            </div>
          ))}
        </Slider>
      ) : (
        <ProductImage
          src="placeholder_image_url"
          alt="Image Not Found"
          style={{ minHeight: "150px" }} // Ajusta el tamaño mínimo aquí
        />
      )}

      {product.name && <ProductName>{product.name}</ProductName>}
      

      {renderColors()}

      {/* Agrega más detalles según sea necesario */}
    </CardContainer>
  );
}

export default ProductCard;
