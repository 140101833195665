import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";


import {
  FreeFormContainer,
  FreeFormImage,
  FreeFormImageImg,
  FreeFormFields,
  FreeFormField,
  H2,
  Label,
  InputText,
  FormButton,
  ButtonContainer,
  Wrapper,
  ThankYouMessage,
  ThankYouContainer,
  AdditionalMessage,
  RedirectMessage,
  CheckmarkIcon,
  FlexCheckboxContainer,
  StyledLink,
  SignatureArea,
} from "./FormFreeStyles";

import CircularProgress from "@mui/material/CircularProgress";
import { db, storage } from "../Firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import SignatureCanvas from "react-signature-canvas";

function FormFree() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    image: "",
    header: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    agreedToLiability: false,
    signatureUrl: "",
  });

  const sigCanvas = useRef(null); // Usar useRef aquí

  const [secondsRemaining, setSecondsRemaining] = useState(5);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [waiverUrl, setWaiverUrl] = useState("");

  const getPDFUrl = async () => {
    const docRef = doc(db, "academies", "x3aWKMi87yl6nMO0RtLh"); // Asume que es la ID del documento que deseas acceder
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().waiverPDF; // Aquí obtiene el campo "waiverPDF" del documento
      } else {
        console.error("Document not found");
      }
    } catch (error) {
      console.error("Error obteniendo URL del PDF: ", error);
    }
  };

  useEffect(() => {
    const fetchWaiverUrl = async () => {
      const url = await getPDFUrl();
      setWaiverUrl(url);
    };

    fetchWaiverUrl();
  }, []);

  const springProps = useSpring({
    to: {
      maxHeight: formData.agreedToLiability ? "350px" : "0px",
      opacity: formData.agreedToLiability ? 1 : 0,
      marginBottom: formData.agreedToLiability ? "20px" : "0px", // Agrega margen inferior
    },
    from: {
      maxHeight: "0px",
      opacity: 0,
      marginBottom: "0px", // Inicializa margen inferior
    },
    overflow: "hidden",
    config: { tension: 200, friction: 20 },
  });

  const [showSignature, setShowSignature] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setFormData((prevState) => ({
      ...prevState,
      agreedToLiability: checked,
    }));
    setShowSignature(checked); // Cambiar la visibilidad de la firma
  };

  const createUserWithDetails = async (signatureURL) => {
    try {
      await addDoc(collection(db, "users"), {
        ...formData,
        signatureUrl: signatureURL,
        timestamp: serverTimestamp(), // Agrega la marca de tiempo del lado del servidor
      });
    } catch (error) {
      throw new Error(`Error creating user: ${error.message}`);
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.phone &&
      formData.agreedToLiability
    ) {
      setLoading(true);
      try {
        if (!formData.signatureUrl && sigCanvas.current) {
          const signatureData = sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png");
          const signatureBlob = dataURLToBlob(signatureData);
          const signatureRef = ref(storage, `waivers/${formData.email}.png`);
          const snapshot = await uploadBytes(signatureRef, signatureBlob);
          const signatureURL = await getDownloadURL(snapshot.ref);
          formData.signatureUrl = signatureURL;
        }
        await addDoc(collection(db, "users"), {
          ...formData,
          timestamp: serverTimestamp(),
        });
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error at uploading data: ", error);
        setErrorMessage("Error submitting the form.");
      } finally {
        setLoading(false);
      }
      // Redirect to home
      window.location.href = '/'; // Replace '/home' with your home page route
    } else {
      setErrorMessage(
        "Please fill all the fields, sign, and agree to the waiver before submitting."
      );
    }
  };

  useEffect(() => {
    let timer;

    if (isSubmitted && secondsRemaining > 0) {
      timer = setInterval(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);
    } else if (isSubmitted && secondsRemaining === 0) {
      clearInterval(timer);
      window.location.reload();
    }

    return () => clearInterval(timer);
  }, [isSubmitted, secondsRemaining]);

  const [formContent, setFormContent] = useState({
    header: "",
    image: "",
  });

  useEffect(() => {
    const fetchFormContent = async () => {
      const docRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh",
        "FormFree",
        "3pXnQk0MNIWkmHpgdJ5K"
      );
      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormContent(docSnap.data()); // Actualiza el estado con los datos obtenidos
        } else {
          console.log("Document not found");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchFormContent();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <ThankYouContainer>
        <ThankYouMessage>Form Successfully Submitted</ThankYouMessage>
        <AdditionalMessage>
          Your request will be processed shortly
        </AdditionalMessage>
        <RedirectMessage>
          Redirecting in {secondsRemaining} seconds...
        </RedirectMessage>
        <CheckmarkIcon />
      </ThankYouContainer>
    );
  }

  return (
    <Wrapper>
      <FreeFormContainer>
        <FreeFormImage>
          <FreeFormImageImg src={formContent.image} alt="Form Image" />
        </FreeFormImage>
        <form onSubmit={handleFormSubmit}>
          <FreeFormFields>
            <H2>One Week Of Free Training At North Side Brazilian Jiu Jitsu</H2>
            <FreeFormField>
              <Label htmlFor="firstName">First Name</Label>
              <InputText
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="Enter your first name..." // Añadir placeholder
              />
            </FreeFormField>
            <FreeFormField>
              <Label htmlFor="lastName">Last Name</Label>
              <InputText
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Enter your last name..." // Añadir placeholder
              />
            </FreeFormField>
            <FreeFormField>
              <Label htmlFor="email">Email</Label>
              <InputText
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email..." // Añadir placeholder
              />
            </FreeFormField>
            <FreeFormField>
              <Label htmlFor="phone">Phone</Label>
              <InputText
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number..." // Añadir placeholder
              />
            </FreeFormField>
            <FreeFormField>
              <FlexCheckboxContainer>
                <input
                  type="checkbox"
                  id="agreed-to-liability"
                  name="agreedToLiability"
                  checked={formData.agreedToLiability}
                  onChange={handleCheckboxChange}
                />
                <Label
                  htmlFor="agreed-to-liability"
                  style={{ marginLeft: "10px" }}
                >
                  I have read and agreed to the{" "}
                  <StyledLink
                    href={waiverUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    release and liability agreement
                  </StyledLink>
                </Label>
              </FlexCheckboxContainer>
            </FreeFormField>

            <animated.div style={{ ...springProps, width: '100%', borderRadius: '10px' }}>
  <Label>Sign Here</Label>
  <SignatureCanvas
    penColor="black"
    canvasProps={{
      className: "signature-canvas",
      style: { backgroundColor: "white", width: '100%', height: '200px', borderRadius: '20px' } // Added borderRadius here
    }}
    ref={sigCanvas}
  />
  <div style={{ display: "flex", marginTop: "10px" }}>
    <FormButton type="button" onClick={clearSignature}>
      Clear Signature
    </FormButton>
  </div>
</animated.div>





            <ButtonContainer>
              <FormButton
                type="submit"
                disabled={!formData.agreedToLiability}
                style={{
                  backgroundColor: formData.agreedToLiability ? "red" : "grey",
                  cursor: formData.agreedToLiability
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                Submit
              </FormButton>
            </ButtonContainer>
          </FreeFormFields>

          {errorMessage && (
            <div
              style={{
                color: "red",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              {errorMessage}
            </div>
          )}
        </form>
      </FreeFormContainer>
    </Wrapper>
  );
}

function dataURLToBlob(dataURL) {
  const parts = dataURL.split(";base64,");
  const imageType = parts[0].split(":")[1];
  const decodedData = window.atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: imageType });
}

export default FormFree;
