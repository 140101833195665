import React from "react";
import Footer from "../Components/Footer/Footer";
import FormFree from "../Components/FormFree";
import PresentationFreePass from "../Components/Presentations/PresentationFreePass";

function FreePass() {
  return (
    <div>
      <PresentationFreePass />

      <FormFree />

      <Footer />
    </div>
  );
}

export default FreePass;
