import styled from 'styled-components';

export const AresContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin: 5%;

  @media (max-width: 768px) {
    margin: 3%;
  }

  @media (max-width: 480px) {
    margin: 1%;
  }
`;

export const AresTextContainer = styled.div`
  flex: 1;
  margin: 50px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 25px;
  }

  @media (max-width: 480px) {
    margin: 15px;
  }
`;

export const AresTitle = styled.div`
  font-size: 24px;
  line-height: 1.1;  
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const TitleP = styled.p`
  margin: 0;
  font-size: 110px;

  @media (max-width: 768px) {
    font-size: 80px;
    margin-top: 20%;
  }

  @media (max-width: 480px) {
    font-size: 60px;
  }
`;

export const AresSubtext = styled.div`
  font-size: 50px;
  margin-top: 10px;
  font-family: 'Asap Condensed', sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 40px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

export const AresText = styled.div`
  p {
    font-size: 24px;
    font-weight: 300;
    text-align: justify;

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`;
