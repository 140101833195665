import React from "react";
import {
  ScheduleContent,
  ScheduleTextContainer,
  ScheduleTitle,
  ScheduleTitleP,
  ScheduleSubtext,
} from "./PresentationScheduleStyles";

function PresentationSchedule() {
  return (
    <ScheduleContent>
      <ScheduleTextContainer>
        <ScheduleTitle>
          <ScheduleTitleP>
            <span className="red-text">Weekly</span> Schedule
          </ScheduleTitleP>
        </ScheduleTitle>
        <ScheduleSubtext>
            Organize your classes for the week
        </ScheduleSubtext>
      </ScheduleTextContainer>
    </ScheduleContent>
  );
}

export default PresentationSchedule;
