import styled from "styled-components";

export const CardContainer = styled.a`
  color: white;
  padding: 0; // Eliminamos el padding
  text-decoration: none; // Eliminamos la subrayado de los enlaces
  margin: 16px;
  display: inline-block;
  vertical-align: top;
  width: calc(33.33% - 32px);
  box-sizing: border-box;
  cursor: pointer; // Hacemos que se muestre el cursor de mano al pasar por encima
  transition: transform 0.3s;
  z-index: 1;
  text-align: center;
  background-color: rgba(256,4,4, 1);
  border-radius: 15px;

 @media (max-width: 600px) {
    width: calc(50% - 32px); // Two columns for screens equal or smaller than 600px
  }

  @media (max-width: 300px) {
    width: calc(100% - 32px); // One column for screens less than 300px
  }

  &:hover {
    transform: scale(1.03); // Efecto de levitación al pasar el mouse por encima
  }
`;

export const ProductImage = styled.img`
  width: 100%; // Ocupa completamente el ancho
  height: auto;
  display: block;
  margin: 0 auto; // Centers the image if needed
  border-top-left-radius: 15px; // Rounds the top-left corner
  border-top-right-radius: 15px; // Rounds the top-right corner
  /* Ensure minHeight is applied where the ProductImage is used if necessary */
`;


export const ProductName = styled.h3`
  /* Estilos adicionales si los necesitas */
`;

export const ProductDescription = styled.p`
  /* Estilos adicionales si los necesitas */
`;

export const ProductColors = styled.p`
  /* Estilos adicionales si los necesitas */
`;

export const ProductPrice = styled.p`
  /* Estilos adicionales si los necesitas */
`;

export const ProductSizes = styled.p`
  /* Estilos adicionales si los necesitas */
`;

export const BuyButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Centra las tarjetas horizontalmente
  align-content: center; // Esta propiedad ayudará a centrarlas si hay espacio verticalmente
  //Todo: testear contra
  //  align-content: start; // Adjusted for top alignment

  margin: 5%;
`;

// Style for images within ProductGrid
export const GridProductImage = styled(ProductImage)`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;
