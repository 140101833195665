import styled from "styled-components";

export const TitleText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

export const PackageItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  width: 100%;
  border-bottom: 1px solid black;

  &:first-child {
    border-bottom-width: 2px;
    ${TitleText} {
      font-size: 24px; /* Tamaño de fuente más grande para el primer child */
    }
  }
`;

export const InfoContainer = styled.div`
  display: column;
`;

export const SubtitleText = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;

export const PriceText = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-top: auto;
`;

export const LineSeparator = styled.div`
  margin-top: 10px;
  width: 100%;
`;
