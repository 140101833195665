import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
`;

const size = {
  tablet: "910px",
  medium: "1024px",
};

export const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 70%;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 500px;
  background-color: rgb(37, 37, 37);
  position: relative;
  border-radius: 20px;

  @media (max-width: ${size.medium}) {
    width: 85%;
  }

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
    width: 90%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }

  form {
    width: 100%; /* Asegura que el formulario ocupe todo el ancho del contenedor */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */

    @media (min-width: 769px) and (max-width: 1023px) {
      max-width: 90%;
    }
  }
`;

export const FormField = styled.div`
  margin-bottom: 0px;
`;

export const Label = styled.label`
  display: block;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: "Be Vietnam Pro", sans-serif;
  color: white;
`;

export const FormImage = styled.div`
flex: 1;
display: flex;
align-self: stretch; // Asegura que se estire para igualar la altura del contenedor padre
overflow: hidden;
min-width: 300px;
border-radius: 20px;

@media (max-width: ${size.tablet}) {
  margin-bottom: 20px;
  width: 100%;
`;

export const FormImageImg = styled.img`
  height: 100%; // Asegura que la imagen cubra la altura completa del contenedor
  width: 100%; // Asegura que la imagen cubra el ancho del contenedor
  object-fit: cover; // Ajusta el tamaño de la imagen para cubrir el área
  object-position: center; // Centra la imagen horizontalmente
`;

export const FormSelect = styled.select`
  // Aquí puedes añadir tus estilos personalizados
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  // ...
`;

export const FormFields = styled.div`
  padding: 20px;
  margin: 0;
  margin-left: 5%;
  align-items: normal;
  text-align: left;

  @media (max-width: ${size.medium}) {
    padding: 3%;
    font-size: 90%;
  }

  @media (max-width: ${size.tablet}) {
    width: 100%;
    font-size: 80%;
  }

  @media (max-width: 425px) {
    width: 100%;
    font-size: 70%;
  }
`;

export const FormH2 = styled.h2`
  font-size: 28px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  padding: 20px;

  max-width: 100%; /* Asegúrate de que no se desborde del contenedor */
  color: white;
  text-align: center;

  @media (max-width: ${size.medium}) {
    font-size: 24px;
  }

  @media (max-width: ${size.tablet}) {
    font-size: 24px;
    margin-bottom: 10px;
    width: 90%; // Ajusta este valor según tus necesidades
    max-width: 100%; // Puedes ajustar este valor según tus necesidades
    overflow: hidden;
    text-align: center;
  }
`;

export const FormLabel = styled.label`
  display: block;
  font-weight: 400;
  width: 50%;
  margin-bottom: 5px;
  font-family: "Be Vietnam Pro", sans-serif;
  color: white;
`;

export const FormInput = styled.input`
  width: 50%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #ccc;
  }
`;

export const AdditionalInput = styled.input`
  border: none;
  border-bottom: 1px solid gray;
  font-size: 14px;
  padding: 5px 0;
  margin-top: 5px;
  width: 35%;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const FormButton = styled.button`
  background-color: rgb(77, 77, 77); /* Color de fondo de los botones */
  color: white; /* Color del texto de los botones */
  border: none; /* Sin borde */
  padding: 10px 30px; /* Espaciado interior */
  margin-right: 10px; /* Espacio entre los botones */
  margin-bottom: 10px;
  cursor: pointer; /* Cambiar el cursor al pasar el mouse sobre los botones */
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-radius: 20px;

  &:hover {
    background-color: #616161; /* Cambiar el color de fondo al pasar el mouse sobre los botones */
  }
`;

export const FlexCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: white;

  &:visited {
    color: white;
  }

  &:hover {
    color: white;
  }

  &:active {
    color: white;
  }
`;

export const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ThankYouMessage = styled.h2`
  text-align: center;
  color: white;
  font-size: 36px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
`;

export const AdditionalMessage = styled.p`
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: "Be Vietnam Pro", sans-serif;
  margin: 0px;
`;

export const RedirectMessage = styled.p`
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: "Be Vietnam Pro", sans-serif;
  margin-bottom: 20px;
`;

export const CheckmarkIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50; // Color de fondo para la palomita
  color: white; // Color de la palomita

  &:before {
    content: "✔"; // Este es el ícono de la palomita
    font-size: 30px;
  }
`;

export const SignatureArea = styled.div`
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  height: ${(props) => (props.show ? "250px" : "0")};
`;
