import React, { useState, useEffect } from "react";
import {
  Content,
  TextContainer,
  Title,
  TitleParagraph,
  Subtext,
  ImageContainer,
  BackgroundImage,
  LogoContainer,
  LogoImage,
  RedText,
  StyledImage,
} from "./PresentationStyles";
import { db } from "../../Firebase"; // Importa tu archivo de Firebase correctamente
import { doc, getDoc, collection } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import ImageWithFixedHeight from "../ImageWithFixedHeight";


function Presentation() {
  const [loading, setLoading] = useState(true);
  const [presentationHomeData, setPresentationHomeData] = useState({
    titleLine1: "",
    titleLine2: "",
    titleLine3: "",
    subtext: "",
    backgroundImage: "",
    logo: "",
  });

  useEffect(() => {
    const fetchPresentationData = async () => {
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      ); // Asume que este es el ID correcto de tu documento en LandingConfiguration
      const presentationHomeDocRef = doc(
        collection(landingConfigDocRef, "Home"),
        "yA0BMOLbx74DEmgwK3uC"
      ); // Cambia 'yourDocumentId' con el ID del documento que quieres acceder
      const docSnap = await getDoc(presentationHomeDocRef);

      if (docSnap.exists()) {
        setPresentationHomeData(docSnap.data());
      } else {
        console.error("Document not found in Presentation");
      }
      setLoading(false);
    };

    fetchPresentationData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: 'relative',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Content>
        
      <TextContainer>
        <Title>
          <TitleParagraph>{presentationHomeData.titleLine1}</TitleParagraph>
          <TitleParagraph>{presentationHomeData.titleLine2}</TitleParagraph>
          <TitleParagraph>
            <RedText>{presentationHomeData.titleLine3}</RedText>
          </TitleParagraph>
        </Title>
        
        <div>
          <Subtext>{presentationHomeData.subtext}</Subtext>
        </div>
        <br />
      <div>
          <a href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
            <ImageWithFixedHeight
              src="/Google_Play_Store_badge_EN.png"
              alt="Available in Google Play"
              fixedHeight={70}
            />
          </a>
          <a href="https://apps.apple.com/us/app/north-side-bjj/id6476119325" target="_blank" rel="noopener noreferrer">
            <ImageWithFixedHeight
              src="/app_store_badge.png"
              alt="Available in the App Store"
              fixedHeight={70}
            />
          </a>
          
        </div>
      </TextContainer>
      

        <ImageContainer>
          <BackgroundImage
            src={presentationHomeData.backgroundImage}
            alt="jiu jitsu"
          />
        </ImageContainer>

        <LogoContainer>
          <LogoImage src={presentationHomeData.logo} alt="logo" />
        </LogoContainer>

        

    </Content>
  );
}

export default Presentation;
