import React from "react";
import PresentationPricing from "../Components/Presentations/PresentationPricing";
import Footer from "../Components/Footer/Footer";
import PricingCards from "../Components/PricingCards/PricingCards";
import WhiteInfo from "../Components/WhiteInfo";

function Pricing() {
  return (
    <div>
      <PresentationPricing />

      <PricingCards />

      <WhiteInfo />

      <Footer />
    </div>
  );
}

export default Pricing;
