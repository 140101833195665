import styled from 'styled-components';

export const HouseRulesContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export const HouseRulesTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  text-align: justify;
  color: white;
  margin: 7%;
  margin-bottom: 0;

  @media (max-width: 768px) {  // Para tablets y dispositivos más pequeños
    padding: 10px;
    margin: 5%;
  }
`;

export const HouseRulesTitle = styled.div`
  font-size: 110px;
  font-weight: bold;
  margin-top: 0;

  @media (max-width: 768px) {  // Para tablets
    font-size: 60px;
    margin-top: 20%
  }

  @media (max-width: 480px) {  // Para dispositivos móviles
    font-size: 40px;
    margin-top: 20%
  }
`;

export const HouseRulesTitleP = styled.p`
  margin: 0;
`;

export const HouseRulesSubtext = styled.div`
  font-size: 54px;
  margin-top: 10px;
  font-family: 'Asap Condensed', sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const HouseRulesText = styled.div`
  font-size: 18px;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 8px 0;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
