import React, { useState, useEffect } from "react";
import {
  WhiteInfoContainer,
  WhiteInfoTitle,
  WhiteInfoParagraph,
  FlexboxContainer,
  FlexboxParagraph,
  LeftColumn,
  RightColumn,
  LeftContent,
} from "./WhiteInfoStyles";
import { db } from "../Firebase"; // Ajusta tu importación según sea necesario
import { doc, getDoc, collection } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import PackageItem from "./PackageItem";

function WhiteInfo() {
  const [loading, setLoading] = useState(true);
  const [itemsLeft, setItemsLeft] = useState([]);
  const [itemsRight, setItemsRight] = useState([]);

  useEffect(() => {
    const fetchWhiteInfoData = async () => {
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      );
      const itemsLeftDoc = await getDoc(
        doc(
          collection(landingConfigDocRef, "WhiteInfo"),
          "NwpiPugP6FHYv3r0mtKF"
        )
      );
      const itemsRightDoc = await getDoc(
        doc(
          collection(landingConfigDocRef, "WhiteInfo"),
          "i7udeVFoMA2ZpHSmMU8g"
        )
      );

      if (itemsLeftDoc.exists) {
        setItemsLeft(itemsLeftDoc.data().items);
      }

      if (itemsRightDoc.exists) {
        setItemsRight(itemsRightDoc.data().items);
      }

      setLoading(false);
    };

    fetchWhiteInfoData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <WhiteInfoContainer>
      <WhiteInfoTitle className="subtext">
        Build your basic concepts faster with private lessons
      </WhiteInfoTitle>
      <FlexboxContainer>
        <LeftColumn>
          <LeftContent>
            {itemsLeft.map((item, index) => (
              <PackageItem
                key={index}
                title={item.title}
                subtitle={item.subtitle}
                price={item.price}
              />
            ))}
          </LeftContent>
        </LeftColumn>
        <RightColumn>
          <LeftContent>
            {itemsRight.map((item, index) => (
              <PackageItem
                key={index}
                title={item.title}
                subtitle={item.subtitle}
                price={item.price}
              />
            ))}
          </LeftContent>
        </RightColumn>
      </FlexboxContainer>
    </WhiteInfoContainer>
  );
}

export default WhiteInfo;
