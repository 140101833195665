import styled from 'styled-components';

export const NewContent = styled.div`
  background-color: black;
  padding: 20px;
  text-align: justify;
  position: relative;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: justify;
  margin-top: 5%;
  margin-bottom: 30px;

  @media (max-width: 768px) { 
    flex-direction: column; // Apila los elementos en dispositivos móviles
  }
`;

export const LeftColumn = styled.div`
  width: 100%;
  margin-right: 5%;

  @media (max-width: 768px) { 
    margin-right: 0; // Quita el margen en dispositivos móviles
  }
`;

export const RightColumn = styled.div`
  width: 100%;
  margin-right: 5%;

  @media (max-width: 768px) { 
    margin-right: 0; // Quita el margen en dispositivos móviles
  }
`;

export const BottomRow = styled.div`
  margin-top: 20px;
`;

export const Paragraph = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: whitesmoke;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 768px) { 
    margin-left: 5%; // Reducir un poco el margen en móviles
    margin-right: 5%;
    font-size: 18px; // Reducir el tamaño de fuente en móviles
  }
`;

export const Header2 = styled.h2`
  font-size: 24px;
  color: whitesmoke;

  @media (max-width: 768px) { 
    text-align: center; // Centra el encabezado en móviles
  }
`;

export const ImageWithBorder = styled.img`
  width: 90%;
  height: auto;
  margin: 10%;
  border: 4px solid white;
  transform: rotate(-10deg);

  @media (max-width: 768px) { 
    margin: 5%; // Reducir un poco el margen en móviles
    width: 60%;
    height: auto;
  }
`;
