import styled from 'styled-components';

export const MonthContainer = styled.div`
  color: white;
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 days of the week */
  grid-gap: 10px;
  padding: 20px;
`;

export const DayCell = styled.div`
  background-color: #282c34; /* Customize your day cell color */
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventMarker = styled.div`
  background-color: #007bff; /* Customize your event marker color */
  color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  cursor: pointer;
`;
