// MobileMenu.js
import React, { useState } from 'react';
import {
  StyledLink,
  RedButton,
  DropdownContainer,
  DropdownButton,
  DropdownContent,
  DropdownItem,
} from './MobileMenuStyles.js'; // Adjust the import path as necessary



function MobileMenu({ toggleMobileMenu, isMobileMenuOpen }) {// Add isMobileMenuOpen as a prop here
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (dropdownName) => {
      if (openDropdown === dropdownName) {
        setOpenDropdown(null);
      } else {
        setOpenDropdown(dropdownName);
      }
    };

  return (
    <div className={isMobileMenuOpen ? "mobile-menu-items active" : "mobile-menu-items"}>
      <StyledLink to="/" className="nav-StyledLink" onClick={toggleMobileMenu}>
        Home
      </StyledLink>
              <DropdownContainer>
                <DropdownButton onClick={() => toggleDropdown('Classes')}>Classes</DropdownButton>
                <DropdownContent className={openDropdown === 'Classes' ? 'active' : ''}>
                  <DropdownItem to="/kids" onClick={toggleMobileMenu}>
                    Kids BJJ
                  </DropdownItem>
                  <DropdownItem to="/adults" onClick={toggleMobileMenu}>
                    Adult Classes
                  </DropdownItem>
                </DropdownContent>
              </DropdownContainer>
              <DropdownContainer>
              <DropdownButton onClick={() => toggleDropdown('Instructors')}>Instructors</DropdownButton>
              <DropdownContent className={openDropdown === 'Instructors' ? 'active' : ''}>
                  <DropdownItem to="/grappling" onClick={toggleMobileMenu}>
                    Grappling
                  </DropdownItem>
                  <DropdownItem to="/striking" onClick={toggleMobileMenu}>
                    Striking
                  </DropdownItem>
                </DropdownContent>
              </DropdownContainer>
              <StyledLink
                to="/schedule"
                className="nav-StyledLink"
                onClick={toggleMobileMenu}
              >
                Schedule
              </StyledLink>
              <DropdownContainer>
              <DropdownButton onClick={() => toggleDropdown('Extras')}>Extras</DropdownButton>
              <DropdownContent className={openDropdown === 'Extras' ? 'active' : ''}>
                  <DropdownItem to="/house-rules" onClick={toggleMobileMenu}>
                    House Rules
                  </DropdownItem>
                  <DropdownItem to="/ares-affiliate" onClick={toggleMobileMenu}>
                    Ares Affiliate
                  </DropdownItem>
                </DropdownContent>
              </DropdownContainer>
              <StyledLink
                to="/pricing"
                className="nav-StyledLink"
                onClick={toggleMobileMenu}
              >
                Pricing
              </StyledLink>

              <StyledLink
                to="/shop"
                className="nav-StyledLink"
                onClick={toggleMobileMenu}
              >
                Shop
              </StyledLink>
              <RedButton
                to="/free-pass" className="nav-StyledLink"
                onClick={toggleMobileMenu}
              >
                Get Started
              </RedButton>
            </div>
  );
}

export default MobileMenu;
