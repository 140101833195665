import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, isWithinInterval, parse, getDay } from "date-fns";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import { containerStyle, buttonStyle, listStyle, listItemStyle, boldTextStyle } from './ListViewStyles';

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function ListView({ classes, events }) {
  const [openDays, setOpenDays] = useState({});
  const [instructorNames, setInstructorNames] = useState({});

  useEffect(() => {
    const uniqueInstructorIds = new Set(classes.map(cls => cls.instructors?.[0]).filter(id => id));
    const fetchInstructorNames = async () => {
      let namesMapping = {};
      for (let instructorId of uniqueInstructorIds) {
        const docRef = doc(db, "users", instructorId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const { firstname, lastname } = docSnap.data();
          namesMapping[instructorId] = `${firstname} ${lastname}`;
        } else {
          console.log(`No such document for instructorId: ${instructorId}`);
          namesMapping[instructorId] = "Unknown Instructor";
        }
      }
      setInstructorNames(namesMapping);
    };
    fetchInstructorNames();
  }, [classes]);

  const convertFirestoreTimestampToDate = (timestamp) => {
    return timestamp?.toDate ? timestamp.toDate() : new Date();
  };

  const formatDateToTime = (date) => {
    if (!(date instanceof Date && !isNaN(date))) {
      console.error('Invalid date object:', date);
      return 'Invalid time';
    }
    return format(date, 'p').toLowerCase();
  };

  const formatTimestamp = (timeString) => {
    if (!timeString || typeof timeString !== 'string') return 'Invalid time';
    const regex = /^(\d{1,2}:\d{2})\s?(am|pm)$/i;
    const match = timeString.toLowerCase().match(regex);
    if (match) {
      // Convert to Date object for consistent comparison
      return parse(`${match[1]} ${match[2]}`, 'h:mm a', new Date());
    } else {
      return new Date(); // Fallback in case of invalid time
    }
  };

  const toggleDay = (day) => setOpenDays(prev => ({ ...prev, [day]: !prev[day] }));

  // Separate processing for classes and events
  const processedClasses = classes.flatMap(cls => {
      return daysOfWeek.map((day, index) => {
        const daySchedule = cls.schedule[day.toLowerCase()];
        // Apply visibility filtering for classes
        if (daySchedule && daySchedule.isVisible === true && daySchedule.startTime !== "") {
          return {
            ...cls,
            startTime: formatTimestamp(daySchedule.startTime),
            endTime: daySchedule.endTime ? formatTimestamp(daySchedule.endTime) : null,
            dayOfWeek: index,
            type: 'class'
          };
        }
        return null;
      }).filter(cls => cls !== null);
    });

    const processedEvents = events.flatMap(event => {
      const eventDate = convertFirestoreTimestampToDate(event.startTime);
      const dayIndex = getDay(eventDate) - 1; // Adjusting because getDay() returns 0 for Sunday
      // Apply visibility filtering for events
      if (isWithinInterval(eventDate, { start: startOfWeek(new Date()), end: endOfWeek(new Date()) })
          && (event.onlyShowTo.length === 0 || event.onlyShowTo === null)) {
        return {
          ...event,
          startTime: formatDateToTime(eventDate),
          endTime: event.endTime ? formatDateToTime(convertFirestoreTimestampToDate(event.endTime)) : null,
          dayOfWeek: dayIndex >= 0 ? dayIndex : 6,
          type: 'event'
        };
      }
      return null;
    }).filter(event => event !== null);

  // Combine and sort by startTime
  let combinedActivities = [...processedClasses, ...processedEvents]
    .sort((a, b) => a.startTime - b.startTime);

  return (
    <div style={containerStyle}>
      {daysOfWeek.map((day, index) => (
        <div key={day}>
          <button style={buttonStyle} onClick={() => toggleDay(day)}>{day}</button>
          {openDays[day] && (
            <ul style={listStyle}>
              {combinedActivities.filter(activity => activity.dayOfWeek === index).map((activity, idx) => (
                <li key={idx} style={listItemStyle}>
                  {activity.type === 'class' ?
                    <React.Fragment>
                      {`${format(activity.startTime, 'p')} - ${format(activity.endTime, 'p')}: `}
                      <span style={boldTextStyle}>{activity.className}</span>
                      {` - ${instructorNames[activity.instructors[0]] || 'Instructor Unknown'}`}
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {`${activity.startTime} - ${activity.endTime} `}
                      <span style={boldTextStyle}>Event: {activity.title}</span>
                    </React.Fragment>
                  }
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}

export default ListView;
