import React, { useState, useEffect } from "react";
const ImageWithFixedHeight = ({ src, className, fixedHeight, style }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const heightRatio = fixedHeight / img.height;
      const scaledWidth = img.width * heightRatio;

      setDimensions({ width: scaledWidth, height: fixedHeight });
    };
    img.src = src;
  }, [src, fixedHeight]);

  return (
    <img
      src={src}
      className={className}
      width={dimensions.width}
      height={dimensions.height}
      style={style}
      alt=""
    />
  );
};

export default ImageWithFixedHeight;