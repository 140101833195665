import React from "react";
import PresentationKids from "../Components/Presentations/PresentationKids";
import Form from "../Components/Form/Form";
import Footer from "../Components/Footer/Footer";
import Gallery from "../Components/Gallery/Gallery";

function KidsBJJ() {
  return (
    <div>
      <PresentationKids />      

      <Form />

      <Gallery collectionName="KidsGallery" />

      <Footer />
    </div>
  );
}

export default KidsBJJ;
