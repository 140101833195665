import styled from 'styled-components';

export const FreePassContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export const FreePassTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  text-align: justify;
  color: white;
  margin: 7%;
  margin-bottom: 0;

  @media (max-width: 768px) {
    margin: 5% 3%; // Reducir el margen para tablets y móviles
    padding: 15px; // Reducir el padding para tablets y móviles
  }

  @media (max-width: 480px) {
    margin: 3%; // Reducir aún más el margen para teléfonos en modo vertical
  }
`;

export const FreePassTitle = styled.div`
  font-size: 110px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 80px; // Reducir el tamaño de fuente para tablets y móviles
    margin-top: 20%; // Reducimos el margen superior para dispositivos móviles
  }

  @media (max-width: 480px) {
    font-size: 50px; // Reducir el tamaño de fuente para teléfonos en modo vertical
  }
`;

export const FreePassTitleP = styled.p`
  margin: 0;
`;

export const FreePassSubtext = styled.div`
  font-size: 48px;
  margin-top: 10px;
  font-family: 'Asap Condensed', sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 28px; // Reducir el tamaño de fuente para tablets y móviles
  }

  @media (max-width: 480px) {
    font-size: 24px; // Reducir el tamaño de fuente para teléfonos en modo vertical
  }
`;

export const FreePassText = styled.div`
  font-size: 18px;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 16px; // Reducir el tamaño de fuente para tablets y móviles
  }

  @media (max-width: 480px) {
    font-size: 14px; // Reducir el tamaño de fuente para teléfonos en modo vertical
  }
`;
