import styled from "styled-components";

export const PdfContainer = styled.div`
  margin: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center; // Centrar contenido horizontalmente
  position: relative;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const PdfViewer = styled.div`
  display: flex;
  justify-content: center; // Centrar contenido horizontalmente
  width: 100%;
  overflow: hidden; // Asegurar que nada se desborde

  @media (max-width: 768px) {
    transform: scale(0.8); // Escala el contenido para adaptarse a pantallas más pequeñas
  }
`;

export const DocumentWrapper = styled.div`
  display: inline-flex; // Ajustarse al tamaño del contenido
`;

export const Title = styled.p`
  color: white;
  text-align: center;
  width: 100%;
  font-size: 80px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 50px; // Disminuir el tamaño de fuente para pantallas más pequeñas
  }
`;

export const ZoomButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  margin: 5px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #555;
  }
`;

export const DraggableDocumentWrapper = styled(DocumentWrapper)`
  cursor: grab;
  overflow: auto;
  transition: transform 0.2s;
  width: 100%; // Esto asegura que el contenedor se ajusta a su contenedor padre

  &:active {
    cursor: grabbing;
  }
`;