import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";  // Importa Firestore
import { getStorage } from "firebase/storage"; // Importa getStorage


// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBgymjnNC5PLTYzdjQ2lZfTXlqI8-huWpI",
  authDomain: "nsbjj-a78a5.firebaseapp.com",
  projectId: "nsbjj-a78a5",
  storageBucket: "nsbjj-a78a5.appspot.com",
  messagingSenderId: "250360274016",
  appId: "1:250360274016:web:41e2727ef41e014bcc7705",
  measurementId: "G-QK8DXNKFGR"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Inicializa Firestore
const db = getFirestore(app);
const storage = getStorage(app);
export { db, storage };
