import styled from "styled-components";

export const PricingCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const CardFilterButton = styled.button`
  background-color: ${(props) =>
    props.active
      ? "#d22929"
      : "rgb(60, 60, 60)"}; // Rojo oscuro cuando está activo
  color: white; /* Color del texto blanco */
  border-radius: 8px;
  border: 0;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  font-family: "Alatsi", sans-serif;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: ${(props) =>
      props.active
        ? "#e64242"
        : "#505050"}; // Rojo más claro en hover para botón activo
  }
`;

export const storeBadgeStyle = {
  maxWidth: "200px", // Ajusta este valor para cambiar el tamaño de las imágenes
  height: "auto",
  marginRight: "10px",
};

export const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 5%;
  align-items: flex-end;
  margin: 10%;
  margin-top: 0%;
  margin-bottom: 5%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center; // Aquí es donde alineamos al centro
    margin: 5%;
    gap: 20px;
  }
`;

export const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.9;
  color: white;
  border-radius: 8px;
  text-align: center;
  height: auto;
  max-width: 300px;
  background-color: #e0e0e0;
  overflow: hidden;
  border: ${({ highlighted }) => (highlighted ? "5px solid white" : "none")};
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const LargeCard = styled.div`
  flex: 1;
  background-color: #e0e0e0;
  border: 5px solid white;
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 8px;
  text-align: center;
  height: auto;
  max-width: 300px;
`;

export const CardTextContainer = styled.div`
  flex: 1;
  margin: 50px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 30px 15px;
  }
`;

export const CardSubtext = styled.p`
  text-align: center;
  font-size: 60px;
  margin-bottom: 5%;
  font-style: normal;
  color: white;
  font-family: "Asap Condensed", sans-serif;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const CardParagraph = styled.p`
  font-size: 24px;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CardHeading = styled.h1`
  font-size: 3em;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

export const RedBackground = styled.div`
  background: linear-gradient(to right, #d85e56, #d22929);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const WhiteBackground = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  height: 100%;
`;

export const RedContent = styled.div`
  margin: 40px;

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

export const WhiteContent = styled.div`
  margin: 10px;

  @media (max-width: 768px) {
    margin: 5px;
  }
`;
