import React from "react";
import PresentationAdults from "../Components/Presentations/PresentationAdults";
import Footer from "../Components/Footer/Footer";

function AdultClasses() {
  return (
    <div>
      <PresentationAdults />

      <Footer />
    </div>
  );
}

export default AdultClasses;
