import React, { useState, useEffect } from "react";
import HouseRulesCard from "../Components/HouseCards/HouseRulesCards";
import {
  RulesContainer,
  Row,
} from "../Components/HouseCards/HouseRulesCardsStyles";
import PresentationHouseRules from "../Components/Presentations/PresentationHouseRules";
import { db } from "../Firebase";  // Asegúrate de que este import apunte correctamente
import { doc, collection, getDocs } from "firebase/firestore";
import Footer from "../Components/Footer/Footer";
import IBJJFGraduation from "../Components/GraduationSystem/IBJJFGraduation";

const HouseRules = () => {
  const [rulesData, setRulesData] = useState([]);

  useEffect(() => {
    const fetchHouseRules = async () => {
      const landingConfigDocRef = doc(db, "LandingConfiguration", "x3aWKMi87yl6nMO0RtLh");
      
      // Referencia a la colección HouseRules
      const rulesCollectionRef = collection(landingConfigDocRef, "HouseRules");
      const rulesSnapshot = await getDocs(rulesCollectionRef);

      const fetchedRules = rulesSnapshot.docs.map(doc => doc.data());

      // Ordenamos las reglas en base al valor "order"
      const sortedRules = fetchedRules.sort((a, b) => a.order - b.order);

      setRulesData(sortedRules);
    };
   
    fetchHouseRules();
  }, []);

  // Función para dividir el array de tarjetas en subgrupos de dos
  const chunkArray = (arr, chunkSize) => {
    let results = [];
    while (arr.length) {
      results.push(arr.splice(0, chunkSize));
    }
    return results;
  };

  const chunkedRulesData = chunkArray([...rulesData], 2);

  return (
    <>
      <PresentationHouseRules />
      <RulesContainer>
        {chunkedRulesData.map((chunk, index) => (
          <Row key={index}>
            {chunk.map((rule) => (
              <HouseRulesCard
                key={rule.title}
                title={rule.title}
                items={rule.items}
              />
            ))}
          </Row>
        ))}
      </RulesContainer>

      <IBJJFGraduation imageUrl={"/ibjjf_graduation.png"}/>

      <Footer />
    </>
  );
};

export default HouseRules;
