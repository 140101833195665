import React, { useState, useEffect } from 'react';
import DayView from './DayView';
import WeekView from './WeekView';
import MonthView from './MonthView';
import { format, startOfWeek, endOfWeek, addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, startOfDay } from 'date-fns';
import { db } from '../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { ViewButton, NavButton, ButtonGroup, CenteredContainer, ToggleButton, NavigationButton, NavigationButtonGroup, ToggleButtonGroup } from './CalendarComponentStyles';

function CalendarComponent({ classes, events }) {
  const [currentView, setCurrentView] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const start = startOfWeek(currentDate);
      const end = endOfWeek(currentDate);
      const q = query(collection(db, "events"), where("startTime", ">=", start), where("startTime", "<=", end));

      const querySnapshot = await getDocs(q);
      const fetchedEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEventList(fetchedEvents);
    };

    fetchEvents();
  }, [currentDate]);

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const navigate = (direction) => {
    switch(direction) {
      case 'prev':
        if (currentView === 'day') {
          setCurrentDate(prevDate => subDays(prevDate, 1));
        } else if (currentView === 'week') {
          setCurrentDate(prevDate => subWeeks(prevDate, 1));
        } else if (currentView === 'month') {
          setCurrentDate(prevDate => subMonths(prevDate, 1));
        }
        break;
      case 'next':
        if (currentView === 'day') {
          setCurrentDate(prevDate => addDays(prevDate, 1));
        } else if (currentView === 'week') {
          setCurrentDate(prevDate => addWeeks(prevDate, 1));
        } else if (currentView === 'month') {
          setCurrentDate(prevDate => addMonths(prevDate, 1));
        }
        break;
      case 'today':
        setCurrentDate(new Date());
        break;
      default:
        break;
    }
  };

 return (
   <CenteredContainer>
       <ToggleButtonGroup>
         <ToggleButton selected={currentView === 'day'} onClick={() => handleViewChange('day')}>
           Day
         </ToggleButton>
         <ToggleButton selected={currentView === 'week'} onClick={() => handleViewChange('week')}>
           Week
         </ToggleButton>
         {/* Month button removed */}
       </ToggleButtonGroup>
       <NavigationButtonGroup>
         <NavigationButton onClick={() => navigate('prev')}>Prev</NavigationButton>
         <NavigationButton onClick={() => navigate('today')}>Today</NavigationButton>
         <NavigationButton onClick={() => navigate('next')}>Next</NavigationButton>
       </NavigationButtonGroup>

       {currentView === 'day' && <DayView date={currentDate} events={eventList} classes={classes} />}
       {currentView === 'week' && <WeekView date={currentDate} events={eventList} classes={classes} />}
//     {currentView === 'month' && <MonthView date={currentDate} events={eventList} classes={classes} />}
   </CenteredContainer>
 );

}

export default CalendarComponent;
