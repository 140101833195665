import styled from 'styled-components';

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  position: relative; // Set a stacking context for absolute positioning
  //width: 100%; // Ensure the container takes the full width
  background-color: black; // Assuming a dark theme
  padding: 20px; // Add padding around the container
  max-width: calc(100vw - 60px); // Adjust this value based on your requirements
  margin: auto;
  overflow-x: hidden;

`;

export const TimeSlotColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  width: 100;
  background-color: #222;
  border-right: 1px solid white; // Add right border
  z-index: 2;

  &:before {
    content: '';
    height: 55px; // Adjust if necessary
    display: block;
  }
`;

export const DayColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 275px;
  min-width: 100px;
  flex-shrink: 0;
  border-left: 1px solid white; // Add left border for separation
`;

export const DayGrid = styled.div`
  display: flex;
  flex-direction: row; // Align day columns in a row
  overflow-x: auto; // Allow horizontal scrolling for the day columns
  -ms-overflow-style: none; // Hide scrollbar for IE and Edge
  scrollbar-width: none; // Hide scrollbar for Firefox
  width: calc(100vw - 162px);
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari, and Opera
  }
//   &::before, &::after {
//      content: '';
//      position: absolute;
//      top: 0;
//      bottom: 0;
//      width: 20px; // Width of the shadow effect
//      pointer-events: none; // Allows clicks to pass through
//      z-index: 10;
//    }
//
//    &::before {
//      left: 100px; // Same as the width of the TimeSlotColumn
//      background: linear-gradient(to right, rgba(255,255,255,0.5), transparent);
//    }
//
//    &::after {
//      right: 0;
//      background: linear-gradient(to left, rgba(0,0,0,0.3), transparent);
//    }

`;



export const WeekGrid = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  grid-gap: 0px; // Adjusted for tighter grid lines
  border: 1px solid white; // Add border to the entire grid
  flex-grow: 1;
`;


export const GridCell = styled.div`
  //min-height: 120px;
  min-height: 30px;
  height: ${props => props.maxHeight || 'auto'};
  border-bottom: 1px solid #444; // Adjusted to white for visibility
//  border-right: 1px solid white; // Add right border for vertical lines
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  box-sizing: border-box; // Ensure padding is included in the height calculation
`;

export const EventItem = styled.div`
  background-color: white;
  color: black;
  margin: 5px;
  padding: 10px;
  border-radius: 15px;
  //max-width: 150px; // Set max width for content
  flex: 0 1 auto;
  cursor: pointer;
  white-space: nowrap;
`;

export const ClassItem = styled.div`
  //height : 150px;
  background-color: red;
  color: white;
  margin: 5px;
  padding: 10px;
  border-radius: 15px;
  flex: 0 1 auto;
  cursor: pointer;
  white-space: nowrap;
`;

// Adjust TopSpacer if needed based on your WeekDayHeader height
export const TopSpacer = styled.div`
  height: 0px;
`;

// Continue with your existing styles for InstructorLine, WeekDayHeader, TimeBlock, ClassBlock, StyledContainer, DayRow, DayContainer...


export const InstructorLine = styled.div`
  font-size: 0.8em;
  color: #fff;
  margin-top: 4px;
`;

export const WeekDayHeader = styled.div`
   grid-column: span 1; /* Ensures alignment with day columns */
    text-align: center;
    border-bottom: 1px solid white; /* Border to separate headers from content */
    padding: 5px; /* Adjust as needed */
    /* Include the day of the month in the header */
    &::after {
      content: attr(data-date);
      display: block;
      font-size: 0.75em; /* Smaller font size for the date */
      color: #ccc; /* Lighter color for the date */
    }
  `;

export const TimeBlock = styled.div`
  border: 1px solid white;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch; // Ensure it stretches to fill the row height
`;

export const ClassBlock = styled.div`
  display: flex;
  flex-direction: row; // Updated to row to align items horizontally
  flex-wrap: wrap; // Allows items to wrap to the next line
  border: 1px solid white;
  padding: 0px;
  box-sizing: border-box;
  align-self: stretch;
`;

export const StyledContainer = styled.div`
  color: 'white', // Ensure text is visible against a black background
  backgroundColor: 'black', // Assuming a black background
  padding: '00px',
  // Add other styles as needed for layout
`;

export const DayRow = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(7, 1fr); /* Update grid layout */
  /* other styles... */
`;

export const DayContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
   max-width: 630px; // Fix the width of the entire container
    margin: auto; // Center the container

`;

export const ScrollableDayGrid = styled.div`
  overflow-x: auto; // Allows horizontal scrolling
  display: flex;
  flex-direction: row;
  width: 1500px; // Subtract the width of the TimeSlotColumn
  margin-left: 0px;
`;