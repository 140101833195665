import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

function PresentationUnderConstruction(){

  const underConstructionImage = `${process.env.PUBLIC_URL}/path-to-your-image/under-construction-image.jpg`;

  return (
    <div style={{ textAlign: 'center', padding: '50px', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
        <h1>Our Store is Under Construction</h1>
        <p>We're working hard to bring you a great shopping experience. In the meantime, you can purchase memberships through our app.</p>

        <img src={process.env.PUBLIC_URL + '/white-belt.PNG'} alt="Under Construction" style={{ maxWidth: '100%', height: 'auto' }} />
        <div style={{ marginTop: '20px' }}>
                <a href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
                  <img src={process.env.PUBLIC_URL + '/Google_Play_Store_badge_EN.png'} alt="Available in Google Play" style={{ height: '70px' }} />
                </a>
                <a href="https://apps.apple.com/us/app/north-side-bjj/id6476119325" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/app_store_badge.png'} alt="Available in the App Store" style={{ height: '70px' }} />
                </a>
              </div>
        <div style={{ marginTop: '20px' }}>
        <Link to="/" style={{ textDecoration: 'none', color: '#fff', background: '#007bff', padding: '10px 20px', borderRadius: '5px' }}>Back Home</Link>
      </div>

    </div>
  );
};

export default PresentationUnderConstruction;
