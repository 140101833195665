import React from "react";
import {
  GrapplingContent,
  GrapplingTextContainer,
  GrapplingTitle,
  GrapplingTitleP,
  GrapplingSubtext,
} from "./PresentationGrapplingStyles";

function PresentationGrappling() {
  return (
    <GrapplingContent>
      <GrapplingTextContainer>
        <GrapplingTitle>
          <GrapplingTitleP>
            <span className="red-text">Grappling</span> Instructors
          </GrapplingTitleP>
        </GrapplingTitle>
        <GrapplingSubtext>
          Masters of the Mat
        </GrapplingSubtext>
      </GrapplingTextContainer>
    </GrapplingContent>
  );
}

export default PresentationGrappling;
