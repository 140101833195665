import React from "react";
import {
  HouseRulesContent,
  HouseRulesTextContainer,
  HouseRulesTitle,
  HouseRulesTitleP,
  HouseRulesSubtext,
} from "./PresentationHouseRulesStyles";

function PresentationHouseRules() {
  return (
    <HouseRulesContent>
      <HouseRulesTextContainer>
        <HouseRulesTitle>
          <HouseRulesTitleP>
            <span className="red-text">House</span> Rules
          </HouseRulesTitleP>
        </HouseRulesTitle>
        <HouseRulesSubtext>
            Things You Need To Know About NSBJJ Training
        </HouseRulesSubtext>
      </HouseRulesTextContainer>
    </HouseRulesContent>
  );
}

export default PresentationHouseRules;
