import React from "react";
import {
  FreePassContent,
  FreePassTextContainer,
  FreePassTitle,
  FreePassTitleP,
  FreePassSubtext,
} from './PresentationFreePassStyles';

function PresentationFreePass() {
  return (
    <FreePassContent>
      <FreePassTextContainer>
        <FreePassTitle>
          <FreePassTitleP>
            <span className="red-text">Sign</span> Up
          </FreePassTitleP>
        </FreePassTitle>
        <div>
          <FreePassSubtext>
            Are You North Side's Next Champion? <br/>Try Out A Free Week Of Brazilian Jiu-Jitsu At North Side
          </FreePassSubtext>
        </div>
      </FreePassTextContainer>
    </FreePassContent>
  );
}

export default PresentationFreePass;
