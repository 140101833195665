import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore'; // Adjust based on your actual imports
import { db } from '../Firebase'; // Adjust the path as needed
import Footer from "../Components/Footer/Footer";
import PresentationSchedule from "../Components/Presentations/PresentationSchedule";
import CalendarView from "../Components/Calendar/Calendar";
// Make sure to import your styled components correctly
import { ToggleContainer, ToggleInput, Slider } from "../Components/Calendar/CalendarStyles"; // Adjust the import path as necessary
import ListView from '../Components/Calendar/ListView'; // Adjust the import path as necessary
import CalendarComponent from '../Components/Calendar/CalendarComponent';

// Placeholder for ListCalendar component
const ListCalendar = () => <div>List View Placeholder</div>;

function Schedule() {
  // Define the isCalendarView state
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [classes, setClasses] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Example: Fetching classes
    const fetchClasses = async () => {
      const classesRef = collection(db, "/academies/x3aWKMi87yl6nMO0RtLh/classes");
      const querySnapshot = await getDocs(classesRef);
      const fetchedClasses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log("Fetched Classes:", fetchedClasses);
      setClasses(fetchedClasses);
    };

    // Example: Fetching events
    const fetchEvents = async () => {
      const eventsRef = collection(db, "events");
      const q = query(eventsRef, where("academyUID", "==", "x3aWKMi87yl6nMO0RtLh")); // Adjust query as needed
      const querySnapshot = await getDocs(q);
      const fetchedEvents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log("Fetched Events:", fetchedEvents); // Print fetched events
      setEvents(fetchedEvents);
    };

    fetchClasses();
    fetchEvents();
  }, []);

  // Function to toggle the view
  const toggleView = () => {
    setIsCalendarView(!isCalendarView);
  };

   return (
     <div style={{ textAlign: "center" }}>
       <PresentationSchedule />

       {/* Toggle and Text Wrapper */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
         {/* Text before the toggle */}
         <span style={{ marginRight: '10px', color: 'white' }}>List View</span>

         <ToggleContainer>
           <ToggleInput type="checkbox" id="viewToggle" checked={isCalendarView} onChange={toggleView} />
           <Slider htmlFor="viewToggle"></Slider>
         </ToggleContainer>

         {/* Text after the toggle */}
         <span style={{ marginLeft: '10px', color: 'white' }}>Calendar View</span>
       </div>

       {/* Conditionally render ListView or CalendarView */}
       {isCalendarView ? <CalendarComponent classes={classes} events={events} /> : <ListView classes={classes} events={events} />}

       <Footer />
     </div>
   );
}

export default Schedule;
