import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  color: white;
  position: relative;
  min-height: 100vh;
  flex-wrap: wrap;
`;

export const TextContainer = styled.div`
  flex: 2;
  margin: 150px;
  padding: 20px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 60px 30px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 1.1;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const TitleParagraph = styled.p`
  margin: 0;
  color: white;
  font-size: ${(props) => (props.small ? '16px' : '20px')};

  @media (max-width: 768px) {
    font-size: 80px;
  }

  @media (max-width: 480px) {
    font-size: 60px;
  }
`;

export const Subtext = styled.div`
  font-size: 40px;
  margin-top: 10px;
  font-family: 'Asap Condensed', sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  overflow: hidden;

  @media (max-width: 768px) {
    position: absolute;
    width: 50%;
  }
`;

export const BackgroundImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  filter: blur(1.5px);
  z-index: 0;
  opacity: 0.7;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media (max-width: 768px) {
    position: static;
    margin-top: 20px;
    text-align: center;
  }
`;

export const LogoImage = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

export const RedText = styled.span`
  color: red;
  font-weight: bold;
  font-size: ${(props) => (props.small ? '18px' : '24px')};
`;


export const InfoText = styled.p`
  font-size: ${(props) => (props.small ? '18px' : '20px')};
  color: ${(props) => (props.color ? props.color : 'white')};
  margin: 20px 0;
`;