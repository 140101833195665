import React from "react";
import {
  PricingContent,
  PricingTextContainer,
  PricingTitle,
  PricingSubtext,
} from './PricingStyles';

function PresentationPricing() {
  return (
    <PricingContent>
      <PricingTextContainer>
        <PricingTitle>
          <p>Pricing</p>
        </PricingTitle>
        <div>
          <PricingSubtext>
            Available Memberships And Private & Group Lessons
          </PricingSubtext>
        </div>
      </PricingTextContainer>
    </PricingContent>
  );
}

export default PresentationPricing;
