import styled from "styled-components";

export const GrapplingContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export const GrapplingTextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  text-align: justify;
  color: white;
  margin: 7%;
  margin-bottom: 0;

  @media (max-width: 768px) {
    // Para dispositivos móviles
    padding: 15px;
    margin: 5%;
  }
`;

export const GrapplingTitle = styled.div`
  font-size: 110px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 60px; // Reducimos el tamaño del título para dispositivos móviles
    margin-top: 80px; // Reducimos el margen superior para dispositivos móviles
  }

  @media (max-width: 480px) {
    font-size: 40px; // Reducimos aún más para pantallas muy pequeñas
    margin-top: 40px; // Reducimos el margen aún más para pantallas muy pequeñas
  }
`;

export const GrapplingTitleP = styled.p`
  margin: 0;
`;

export const GrapplingSubtext = styled.div`
  font-size: 54px;
  margin-top: 10px;
  font-family: "Asap Condensed", sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 32px; // Reducimos el tamaño del subtítulo para dispositivos móviles
  }

  @media (max-width: 480px) {
    font-size: 24px; // Reducimos aún más para pantallas muy pequeñas
  }
`;

export const GrapplingText = styled.div`
  font-size: 18px;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 16px; // Reducimos el tamaño del texto principal para dispositivos móviles
  }
`;
