import React from 'react';
import { CardContainer, CardTitle, CardList } from './HouseRulesCardsStyles';

const HouseRulesCard = ({ title, items }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <CardList>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </CardList>
  </CardContainer>
);

export default HouseRulesCard;