import React, { useEffect, useState } from 'react';
import {
  NewContent,
  Columns,
  LeftColumn,
  RightColumn,
  Header2,
  Paragraph,
  BottomRow,
  ImageWithBorder,
} from "./AboutUsStyles";
import { db } from "../../Firebase"; // Asegúrate de importar tu archivo de Firebase correctamente
import { doc, collection, getDocs } from "firebase/firestore";

function AboutUs() {
  const [data, setData] = useState({
    imageSrc: '',
    header: '',
    paragraphs: [], // Inicializar como array vacío
  });
  const [loading, setLoading] = useState(true); // Nuevo estado para manejar el estado de carga

  useEffect(() => {
    const fetchAboutUsData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(db, "LandingConfiguration", "x3aWKMi87yl6nMO0RtLh");

      // Referencia a la colección anidada "AboutUs"
      const aboutUsCollectionRef = collection(landingConfigDocRef, "AboutUs");

      const aboutUsSnapshot = await getDocs(aboutUsCollectionRef);

      if (aboutUsSnapshot.docs.length > 0) {
        const aboutUsData = aboutUsSnapshot.docs[0].data();
        setData(aboutUsData);
        setLoading(false); // Establecer loading en false después de obtener los datos
      } else {
        console.error("Documento no encontrado en AboutUs");
        setLoading(false); // Establecer loading en false incluso si no hay datos
      }
    };

    fetchAboutUsData();
  }, []);

  if (loading) return <p>Cargando...</p>; // Mostrar un indicador de carga mientras los datos están siendo obtenidos

  return (
    <NewContent>
      <Columns>
        <LeftColumn>
          <ImageWithBorder
            src={data.image}
            alt="About Us Image"
          />
        </LeftColumn>
        <RightColumn>
          <Header2>{data.header}</Header2>
          {data.paragraph.slice(0, 3).map((paragraph, index) => (
            <Paragraph key={index}>{paragraph}</Paragraph>
          ))}
        </RightColumn>
      </Columns>
      <BottomRow>
        {data.paragraph.slice(3).map((paragraph, index) => (
          <Paragraph key={index}>{paragraph}</Paragraph>
        ))}
      </BottomRow>
    </NewContent>
  );
}

export default AboutUs;