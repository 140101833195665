import React from 'react'
import { LoadingContainer, LoadingText } from './LoadingStyles'

function Loading() {
  return (
    <LoadingContainer>
        <LoadingText>Loading...</LoadingText>
    </LoadingContainer>

  )
}

export default Loading