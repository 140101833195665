import React, { useState, useEffect } from "react";
import MobileMenu from "./MobileMenu.js";
import {
  NavbarContainer,
  StyledLink,
  RedButton,
  DropdownContainer,
  DropdownButton,
  DropdownContent,
  DropdownItem,
} from "./NavbarStyles.js";

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };
    

    window.addEventListener("resize", handleResize);

    // Limpiar el detector al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileMenuOpen]); // Dependencias del efecto: el estado del menú móvil

  return (
    <NavbarContainer>
      <div onClick={toggleMobileMenu} className="mobile-menu-icon">
        &#9776;
      </div>

      <div className="navbar-brand-mobile">NSBJJ</div>
      {isMobileMenuOpen && <MobileMenu toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={isMobileMenuOpen} />}

      <div className="desktop-menu-items">
        <StyledLink to="/" className="nav-StyledLink">
          Home
        </StyledLink>
        <DropdownContainer>
          <DropdownButton>Classes</DropdownButton>
          <DropdownContent>
            <DropdownItem to="/kids">Kids BJJ</DropdownItem>
            <DropdownItem to="/adults">Adult Classes</DropdownItem>
          </DropdownContent>
        </DropdownContainer>
        <DropdownContainer>
          <DropdownButton>Instructors</DropdownButton>
          <DropdownContent>
            <DropdownItem to="/grappling">Grappling</DropdownItem>
            <DropdownItem to="/striking">Striking</DropdownItem>
          </DropdownContent>
        </DropdownContainer>
        <StyledLink to="/schedule" className="nav-StyledLink">
          Schedule
        </StyledLink>
        <DropdownContainer>
          <DropdownButton>Extras</DropdownButton>
          <DropdownContent>
            <DropdownItem to="/house-rules">House Rules</DropdownItem>
            <DropdownItem to="/ares-affiliate">Ares Affiliate</DropdownItem>
          </DropdownContent>
        </DropdownContainer>
        <StyledLink to="/pricing" className="nav-StyledLink">
          Pricing
        </StyledLink>
        <StyledLink to="/shop" className="nav-StyledLink">
          Shop
        </StyledLink>
        <RedButton to="/free-pass" className="nav-StyledLink">
          Get Started
        </RedButton>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
