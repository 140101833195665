import React from 'react';

const GoogleMapComponent = () => {
  return (
    <div style={{ width: '100%', height: '450px', overflow: 'hidden' }}>
      <iframe 
        title="North Side Brazilian Jiu-Jitsu Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3373.2494878284383!2d-110.97983528798962!3d32.27826967376711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d6737318a91421%3A0x72afcbd78b9694f5!2sNorth%20Side%20Brazilian%20Jiu-Jitsu!5e0!3m2!1ses-419!2smx!4v1698443359241!5m2!1ses-419!2smx" 
        width="100%" 
        height="100%" 
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default GoogleMapComponent;
