import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { formatISO, format } from "date-fns";
import { db } from "../../Firebase";
import { doc, collection, getDocs } from "firebase/firestore";

import { FaChevronDown } from "react-icons/fa";

const EventList = styled.div`
  font-family: "Be Vietnam", sans-serif;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 2%;
  }
`;

const DayEvents = styled.div`
  background-color: #505050;
  opacity: 0.9;
  padding: 5px;
  border-radius: 3px;
  color: white;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 2px 0;
  gap: 5px;
`;

const EventsContainer = styled.div`
  background-color: #303030;
  border-radius: 3px;
  margin: 10px 20px;
  padding: 5px;
`;

const EventTime = styled.span`
  font-weight: bold;
  font-size: 1.2em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const EventDescription = styled.span`
  font-size: 1em;
  color: #ddd;
`;

const EventInstructor = styled.span`
  font-size: 0.9em;
  color: #bbb;
`;

const DayTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: 1%; // Cambio aquí

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 3px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ArrowIcon = styled(FaChevronDown)`
  margin-left: 10px;
  display: inline-block;
  transform: rotate(${(props) => (props.expanded ? "180deg" : "0deg")});
  transition: transform 0.3s;
  color: white; // Color del ícono.
`;

const timestampToTime = (date) => {
  return format(date, "hh:mm a");
};

const compareEventsByStartTime = (a, b) => {
  return a.startTime - b.startTime;
};




const transformClassesToWeekData = (classes) => {
  const weekData = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };

  classes.forEach(classData => {
    Object.keys(classData.schedule).forEach(day => {
      if (classData.schedule[day].startTime) { // Assuming a class is scheduled if startTime is set
        weekData[day].push({
          ...classData,
          schedule: classData.schedule[day]
        });
      }
    });
  });

  return weekData;
};

const parseTime = (timeString, referenceDate) => {
  if (!timeString) {
    return null; // or return a default value if needed
  }

  // Extract hours, minutes, and optional modifier (am/pm)
  let [time, modifier] = timeString.split(' ');
  if (!modifier) {
    // Check if the modifier is attached to the time (e.g., "6:00pm")
    const match = time.match(/(\d+:\d+)(am|pm)?/i);
    if (match) {
      time = match[1];
      modifier = match[2];
    }
  }

  let [hours, minutes] = time.split(':');
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  // Adjust hours based on the modifier
  if (modifier) {
    if (modifier.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    }
    if (modifier.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }
  }

  const date = new Date(referenceDate);
  date.setHours(hours, minutes);
  return date;
};





// function timestampToTime(date) {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   // Formatting the hours in 12-hour format and determining the AM/PM suffix
//   const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
//   const amPm = hours >= 12 ? 'pm' : 'am';

//   // Ensuring minutes are two digits
//   const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

//   return `${formattedHours}:${formattedMinutes} ${amPm}`;
// }


// Use this function to transform your fetched classes


function WeekEvents({ weekRange, academyUID }) {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedDays, setExpandedDays] = useState([]);


  useEffect(() => {
    const fetchClassesData = async () => {
      const classesRef = collection(db, "/academies/x3aWKMi87yl6nMO0RtLh/classes");
  
      try {
        const querySnapshot = await getDocs(classesRef);
        const fetchedClasses = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(cls => cls.isVisible !== false); // Exclude classes where isVisible is explicitly set to false
  
        const weekClasses = transformClassesToWeekData(fetchedClasses);
        setEventsData(weekClasses); // Set the transformed data to state
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
  
    fetchClassesData();
  
  }, [weekRange, academyUID]);
  
  function sortEventsByTime(events) {
    return events.sort((a, b) => {
      // Extract the time part from the event string
      const timeRegex = /(\d{2}:\d{2} [AP]M)/;
      let [timeA] = a.match(timeRegex) || [];
      let [timeB] = b.match(timeRegex) || [];
  
      // Convert 12-hour time to 24-hour time for comparison
      timeA = convertTo24Hour(timeA);
      timeB = convertTo24Hour(timeB);
  
      return timeA.localeCompare(timeB);
    });
  }
  
  function convertTo24Hour(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
  
    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
  
    return `${hours}:${minutes}`;
  }

  const handleDayClick = (date) => {
    if (expandedDays.includes(date)) {
      setExpandedDays((prevDays) => prevDays.filter((day) => day !== date));
    } else {
      setExpandedDays((prevDays) => [...prevDays, date]);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const getDateOfWeek = (dayName, referenceDate = new Date()) => {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayIndex = daysOfWeek.indexOf(dayName.toLowerCase());
    const currentDayIndex = referenceDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const difference = dayIndex - currentDayIndex;
    referenceDate.setDate(referenceDate.getDate() + difference);
    return referenceDate;
  };

  const getDateForDayOfWeek = (dayName) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const today = new Date();
    const todayDayIndex = today.getDay();
    const dayIndex = days.indexOf(dayName);
  
    // Calculate the date difference
    const dateDifference = dayIndex - todayDayIndex;
    today.setDate(today.getDate() + dateDifference);
    
    return today;
  };

  const findFirstValidStartTime = (schedule) => {
    if (!schedule) {
      return null; // Handle the case where schedule is undefined
    }
  
    // Iterate over the days and find the first valid start time
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    for (const day of days) {
      const startTime = schedule[day]?.startTime;
      if (startTime && startTime !== "") {
        return startTime;
      }
    }
  
    return null; // Handle the case where no valid start time is found
  };
  
  
  return (
    <EventList>
      {Object.keys(eventsData).map((dayOfWeek) => {
        const parsedDate = getDateOfWeek(dayOfWeek); // This function needs to be defined in your code
        const dateForDay = getDateForDayOfWeek(dayOfWeek); // Convert day name to date
        const formattedDayOfWeek = format(dateForDay, 'EEEE');
  
        // Sort events for the day
        const sortedEvents = eventsData[dayOfWeek].sort((a, b) => {
          const startTimeA = a.schedule['startTime'] ? parseTime(a.schedule['startTime'], parsedDate) : null;
          const startTimeB = b.schedule['startTime'] ? parseTime(b.schedule['startTime'], parsedDate) : null;
  
          if (!startTimeA || !startTimeB) return 0; // Handle cases where time is not available
  
          return startTimeA - startTimeB; // Sort based on date objects
        });
  
        return (
          <DayEvents key={dayOfWeek}>
            <DayTitle onClick={() => handleDayClick(dayOfWeek)}>
              {formattedDayOfWeek}
              <ArrowIcon expanded={expandedDays.includes(dayOfWeek)} />
            </DayTitle>
            {expandedDays.includes(dayOfWeek) && (
              <EventsContainer>
                {sortedEvents.map((classData, index) => {
                  const startTime = classData.schedule['startTime'] ? parseTime(classData.schedule['startTime'], parsedDate) : null;
                  const endTime = classData.schedule['endTime'] ? parseTime(classData.schedule['endTime'], parsedDate) : null;
  
                  return (
                    <Event key={index}>
                      <EventTime>
                        {startTime ? timestampToTime(startTime) : 'TBD'} - 
                        {endTime ? timestampToTime(endTime) : 'TBD'}
                      </EventTime>
                      <EventDescription>{classData.className}</EventDescription>
                      <EventInstructor>
                        Instructor: {classData.instructors[0]}
                      </EventInstructor>
                    </Event>
                  );
                })}
              </EventsContainer>
            )}
          </DayEvents>
        );
      })}
    </EventList>
  );
  
}

export default WeekEvents;
