import React, { useState, useEffect } from "react";
import { db } from "../../Firebase"; // Ajusta esta importación a tu configuración
import { doc, getDoc } from "firebase/firestore";
import {
  PdfContainer,
  Title,
  PdfViewer,
  DocumentWrapper,
  ZoomButton,
} from "./WaiverStyles";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const getPDFUrl = async () => {
  const docRef = doc(db, "academies", "x3aWKMi87yl6nMO0RtLh"); // Asume que es la ID del documento que deseas acceder
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().waiverPDF; // Aquí obtiene el campo "waiverPDF" del documento
    } else {
      console.error("Documento no encontrado");
    }
  } catch (error) {
    console.error("Error obteniendo URL del PDF: ", error);
  }
};

export default function WaiverPDF({ title }) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const fetchPDF = async () => {
      const url = await getPDFUrl();
      setPdfUrl(url);
    };
    fetchPDF();
  }, []);

  // Estado y funciones para el arrastre
  const [isDragging, setIsDragging] = useState(false);
  const [originX, setOriginX] = useState(0);
  const [translationX, setTranslationX] = useState(0);
  const [translationY, setTranslationY] = useState(0);
  const [originY, setOriginY] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setOriginX(event.clientX - translationX);
    setOriginY(event.clientY - translationY);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const newTranslationX = event.clientX - originX;
      const newTranslationY = event.clientY - originY;
      setTranslationX(newTranslationX);
      setTranslationY(newTranslationY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.6) {
      setScale((prevScale) => prevScale - 0.1);
    }
  };

  if (!pdfUrl) return <div>Loading...</div>;

  return (
    <PdfContainer>
      <Title>{title}</Title>
      <ZoomButton onClick={handleZoomIn}>Zoom In</ZoomButton>
      <ZoomButton onClick={handleZoomOut}>Zoom Out</ZoomButton>
      <PdfViewer>
        <DocumentWrapper>
          <div
            style={{
              transform: `translate(${translationX}px, ${translationY}px)`,
              cursor: isDragging ? "grabbing" : "grab",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <Document file={pdfUrl}>
              <Page
                pageNumber={1}
                scale={scale}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </div>
        </DocumentWrapper>
      </PdfViewer>
    </PdfContainer>
  );
}
