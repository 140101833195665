import styled from "styled-components";

export const VideoSectionContainer = styled.div`
  display: flex;
  flex-direction: row; // Asegúrate de que en pantallas más grandes esté en fila
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: black;
  position: relative;

  @media (max-width: 768px) { // Cuando el ancho de pantalla sea 768px o menor
    flex-direction: column; // Cambia a columna para que estén uno encima del otro
  }
`;

export const StyledIframe = styled.iframe`
  width: 500px;
  height: 300px;
  order: 2;

  @media (max-width: 768px) {
    width: 80%; // Ajusta el ancho al 100% en dispositivos móviles
    height: auto;
    order: 1;
  }
`;

export const TextSection = styled.div`
  margin-right: 20px;
  color: white;
  width: 30%;
  order: 1;

  @media (max-width: 768px) {
    margin-right: 0; // Elimina el margen derecho en dispositivos móviles
    width: 100%; // Ajusta el ancho al 100% en dispositivos móviles
    margin-top: 20px; // Añade margen superior para espacio entre el video y el texto
    order: 2;
  }
`;

export const H2 = styled.h2`
  font-size: 32px;
  font-weight: 600;
  line-height: 112.1%;

  @media (max-width: 768px) {
    font-size: 22px; // Reduce el tamaño de fuente en móviles
    margin: 20px;
  }
`;

export const P = styled.p`
  text-align: justify;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 112.1%;

  @media (max-width: 768px) {
    font-size: 18px; // Reduce el tamaño de fuente en móviles
    margin: 20px;
  }
`;

export const VideoPlaceholder = styled.div`
  width: 400px;
  height: 250px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  border: 2px solid #333;
`;

export const PlayIcon = styled.div`
  color: #333;
  cursor: pointer;
`;
