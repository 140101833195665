// ListViewStyles.js
export const containerStyle = {
    color: 'white',
    backgroundColor: 'black',
    padding: '20px',
    // Add a maximum width of 600px for screens wider than 630px
    maxWidth: '600px',
    margin: '0 auto', // Center the container
};

export const buttonStyle = {
    backgroundColor: 'red',
    color: 'black',
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px', // Slightly rounded corners for the day buttons
    fontWeight: 'bold', // Make the font bold
    fontSize: '18px', // Larger font size for the weekdays
};

export const listStyle = {
    listStyleType: 'none',
    paddingLeft: '0',
    textAlign: 'left',
};

export const listItemStyle = {
    marginBottom: '10px',
};

export const boldTextStyle = {
  fontWeight: 'bold',
};
