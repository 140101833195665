import React, { useEffect, useState } from "react";
import {
  WhiteBannerContainer,
  WhiteBannerTitle,
  WhiteBannerParagraph,
} from "./WhiteBannerStyles";
import { db } from "../../Firebase"; // Asegúrate de importar tu archivo de Firebase correctamente
import { doc, getDocs, collection } from "firebase/firestore";

function WhiteBanner() {
  const [data, setData] = useState({
    title: "",
    paragraph: "",
  });

  useEffect(() => {
    const fetchWhiteBannerData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      );

      // Referencia a la colección anidada "WhiteBanner"
      const whiteBannerCollectionRef = collection(
        landingConfigDocRef,
        "WhiteBanner"
      );

      const whiteBannerSnapshot = await getDocs(whiteBannerCollectionRef);

      // Asumiendo que solo hay un documento en la colección "WhiteBanner"
      if (!whiteBannerSnapshot.empty) {
        const whiteBannerData = whiteBannerSnapshot.docs[0].data();
        setData({
          title: whiteBannerData.title,
          paragraph: whiteBannerData.paragraph,
        });
      } else {
        console.error("Documento no encontrado en WhiteBanner");
      }
    };

    fetchWhiteBannerData();
  }, []);

  return (
    <WhiteBannerContainer>
      <WhiteBannerTitle>
        {data.title.prefix}
        <strong> {data.title.boldWord} </strong>
        {data.title.suffix}
      </WhiteBannerTitle>
      <WhiteBannerParagraph> { data.paragraph } </WhiteBannerParagraph>
    </WhiteBannerContainer>
  );
}

export default WhiteBanner;
