import React from "react";
import styled from 'styled-components';

// Definimos los componentes de styled-components
export const PricingContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export const PricingTextContainer = styled.div`
  flex: 1;
  margin: 50px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

export const PricingTitle = styled.div`
  font-size: 24px;
  line-height: 1.1;
  margin-top: 5%;
  
  p {
    margin: 0;
    font-size: 110px;
    font-weight: 700;
    font-family: 'Be Vietnam Pro', sans-serif;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    p {
      font-size: 60px;
    }
    margin-top: 20%;
  }
`;

export const PricingSubtext = styled.p`
  font-size: 60px;
  margin-top: 10px;
  font-family: 'Asap Condensed', sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const PricingText = styled.p`
  font-size: 24px;
  font-weight: 300;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
