import React, { useState, useEffect } from "react";
import {
  Content,
  TextContainer,
  Title,
  TitleParagraph,
  Subtext,
  ImageContainer,
  BackgroundImage,
  LogoContainer,
  LogoImage,
  RedText,
} from "./PresentationMerchStyles";
import { db } from "../../Firebase"; // Importa tu archivo de Firebase correctamente
import { doc, getDoc, collection } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import ImageWithFixedHeight from "../ImageWithFixedHeight";

function PresentationMerch() {
  const [loading, setLoading] = useState(true);
  const [presentationMerchData, setPresentationMerchData] = useState({
    title: "",
    subtext: "",
    backgroundImage: "",
  });

  useEffect(() => {
    const fetchPresentationData = async () => {
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      ); // Asume que este es el ID correcto de tu documento en LandingConfiguration
      const presentationMerchDocRef = doc(
        collection(landingConfigDocRef, "Merch"),
        "jsfzzlMeovgZCxoBMW8D"
      ); // Cambia 'yourDocumentId' con el ID del documento que quieres acceder
      const docSnap = await getDoc(presentationMerchDocRef);

      if (docSnap.exists()) {
        setPresentationMerchData(docSnap.data());
      } else {
        console.error("Document not found in Presentation");
      }
      setLoading(false);
    };

    fetchPresentationData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Content>
      <TextContainer>
        <Title>
          <TitleParagraph>{presentationMerchData.title}</TitleParagraph>
        </Title>
        <div>
          <Subtext>{presentationMerchData.subtext}</Subtext>
        </div>
        <br />
        {/* Para comprar productos de NSBJJ lo puedes hacer desde nuestra app en las siguientes plataformas */}
        <Title>
          <RedText>To buy NSBJJ products you can do it from our app on the following platforms</RedText>
        </Title>
        <br />

        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <ImageWithFixedHeight
              src="/Google_Play_Store_badge_EN.png"
              alt="Available in Google Play"
              fixedHeight={70}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/north-side-bjj/id6476119325"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageWithFixedHeight
              src="/app_store_badge.png"
              alt="Available in the App Store"
              fixedHeight={70}
            />
          </a>
          
        </div>
      </TextContainer>
      <ImageContainer>
        <BackgroundImage
          src={presentationMerchData.backgroundImage}
          alt="dojo"
        />
      </ImageContainer>
    </Content>
  );
}

export default PresentationMerch;
