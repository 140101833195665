import styled from "styled-components";

export const GalleryContainer = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const GalleryRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const GalleryItem = styled.div`
  margin: 25px;

  @media (max-width: 768px) {
    width: calc(
      50% - 50px
    ); // En dispositivos móviles, las imágenes ocuparán aproximadamente la mitad del ancho, restando los márgenes
    display: flex;
    justify-content: center;
  }
`;

export const ImageBox = styled.img`
  width: 350px;
  height: 350px;
  object-fit: cover; // Para que la imagen cubra el espacio sin deformarse

  @media (max-width: 768px) {
    width: 175px; // En dispositivos móviles, las imágenes usarán todo el ancho disponible del GalleryItem
    height: 175px; // Permite que la altura se ajuste proporcionalmente
  }
`;
