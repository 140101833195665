import styled from "styled-components";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alineación ajustada a la izquierda */
`;

export const FlexCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
  gap: 20px;
  margin: 0 20px; /* Agregamos margen a los lados */
`;

export const Text = styled.p`
  font-size: ${(props) => (props.lg ? "24px" : props.md ? "18px" : "14px")};
  font-family: "Be Vietnam Pro", sans-serif;
  color: white;
  width: ${(props) => props.width || "100%"};
  max-width: 600px; /* Ancho máximo para la descripción */
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  margin: 0;
  margin-bottom: 10px;
  color: ${(props) => props.color || "white"};
`;

export const Image = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  display: block;
  margin: 0; /* Quitamos el margen para centrar horizontalmente */
  margin-right: 20px; /* Añadimos un espacio entre la imagen y el texto */
`;

export const Button = styled.button`
  padding: 8px 16px;
  background-color: red;
  color: white;
  width: ${(props) => props.width || "100%"};
  max-width: 200px; /* Ancho máximo para evitar que se extienda demasiado */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  align-self: flex-start; /* Alineamos el botón a la izquierda */

  &:hover {
    background-color: #b30000;
  }
`;
