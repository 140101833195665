import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KidsBJJ from "./Views/KidsBJJ";
import AdultClasses from "./Views/AdultClasses";
import FreePass from "./Views/FreePass";
import Pricing from "./Views/Pricing";
import Grappling from "./Views/Grappling";
import Striking from "./Views/Striking";
import NextSteps from "./Views/NextSteps";
import WaiverPDF from "./Components/Waiver/WaiverPDF";
import AresAffiliate from "./Views/AresAffiliate";
import HouseRules from "./Views/HouseRules";
import Merch from "./Views/Merch";
import Schedule from "./Views/Schedule";
import ProductInfo from "./Views/ProductInfo";
import PricingDetails from "./Components/PricingCards/PricingDetails";
import PresentationUnderConstruction from "./Views/PresentationUnderConstruction";

import Home from "./Views/Home";
import Navbar from "./Components/Navbar/Navbar";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kids" element={<KidsBJJ />} />
          <Route path="/adults" element={<AdultClasses />} />
          <Route path="/free-pass" element={<FreePass />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/grappling" element={<Grappling />} />
          <Route path="/striking" element={<Striking />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/weekly-schedule" element={<Schedule />} />
          <Route path="/under-construction" element={<PresentationUnderConstruction />} />
          <Route path="/next-steps" element={ <NextSteps />} />


          <Route
            path="/waiver"
            element={<WaiverPDF title="Release from Liability and Agreement" />}
          />
          <Route path="/ares-affiliate" element={<AresAffiliate />} />
          <Route path="/house-rules" element={<HouseRules />} />
          <Route path="/shop" element={<Merch />} />
          {/* Product id */}
          <Route path="/product/:id" element={<ProductInfo />} />
          {/* Agrega rutas para otras vistas aquí */}
          {/* Ruta para los detalles de las tarjetas de precios */}
          <Route
            path="/pricing-details/:collectionName/:id"
            element={<PricingDetails />}
          />
          {/* Ruta por defecto para manejar URLs no encontradas */}
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
