import React from 'react';
import { CardWrapper, CardImage, CardText, HoverContent, CardName, CardSubtitle } from './InstructorCardsStyles';

function InstructorCard({ name, imageSrc, text, subtitle }) {
  return (
    <CardWrapper>
      <HoverContent>
        <CardText>{text}</CardText>
      </HoverContent>
      <CardImage src={imageSrc} alt={name} className="card-image" />
      <CardName>{name}</CardName>
      <CardSubtitle>{subtitle}</CardSubtitle>
    </CardWrapper>
  );
}

export default InstructorCard;
