import React from "react";
import {
  PackageItemContainer,
  InfoContainer,
  TitleText,
  SubtitleText,
  PriceText,
  LineSeparator,
} from './PackageItemStyles';

function PackageItem(props) {
  return (
    <PackageItemContainer>
      <InfoContainer>
        <TitleText>{props.title}</TitleText>
        <SubtitleText>{props.subtitle}</SubtitleText>
      </InfoContainer>
      <PriceText>{props.price}</PriceText>
      <LineSeparator />
    </PackageItemContainer>
  );
}

export default PackageItem;
