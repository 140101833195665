import React, { useState, useEffect } from "react";
import {
  KidsContent,
  KidsTextContainer,
  KidsTitle,
  TitleP,
  KidsSubtext,
  KidsText,
  KidsList,
  KidsListItemStyle,
  KidsListItem,
} from "./PresentationKidsStyles";
import { db } from "../../Firebase"; // Asegúrate de importar tu archivo de Firebase correctamente
import { doc, getDocs, collection } from "firebase/firestore";
import Gallery from "../Gallery/Gallery";

function PresentationKids() {
  const [data, setData] = useState({
    title: "",
    highlightedText: "",
    subtext: "",
    list_subtexts: [],
    firstList: [],
    secondList: [],
  });

  useEffect(() => {
    const fetchKidsData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      );

      // Referencia a la colección anidada "Kids"
      const kidsCollectionRef = collection(landingConfigDocRef, "Kids");

      const kidsSnapshot = await getDocs(kidsCollectionRef);

      // Asumiendo que solo hay un documento en la colección "Kids"
      if (!kidsSnapshot.empty) {
        const kidsData = kidsSnapshot.docs[0].data();
        setData({
          titleRed: kidsData.titleRed,
          title: kidsData.title,
          subtext: kidsData.subtext,
          firstList: kidsData.firstList || [],
          secondList: kidsData.secondList || [],
          list_subtexts: kidsData.list_subtexts || [],
        });
      } else {
        console.error("Documento no encontrado en Kids");
      }
    };

    fetchKidsData();
  }, []);

  return (
    <KidsContent>
      <KidsTextContainer>
        <KidsTitle>
          <TitleP>
            <span className="red-text">{data.titleRed}</span> {data.title}
          </TitleP>
        </KidsTitle>
        <KidsSubtext>{data.subtext}</KidsSubtext>
      </KidsTextContainer>

      <Gallery collectionName="KidsTopGallery" />

      <KidsTextContainer>
        {data.list_subtexts[0] && (
          <KidsListItemStyle>{data.list_subtexts[0]}</KidsListItemStyle>
        )}
        <KidsList>
          {data.firstList.map((paragraph, index) => (
            <KidsListItem key={index}>
              {paragraph.split("<br />").map((frag, idx, array) => (
                <React.Fragment key={idx}>
                  {frag}
                  {idx !== array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </KidsListItem>
          ))}
        </KidsList>

        {data.list_subtexts[1] && (
          <KidsListItemStyle>{data.list_subtexts[1]}</KidsListItemStyle>
        )}
        <KidsList>
          {data.secondList.map((paragraph, index) => (
            <KidsListItem key={index}>
              {paragraph.split("<br />").map((frag, idx, array) => (
                <React.Fragment key={idx}>
                  {frag}
                  {idx !== array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </KidsListItem>
          ))}
        </KidsList>

        {data.list_subtexts.slice(2).map((subtext, index) => (
          <KidsListItemStyle key={index + 2}>{subtext}</KidsListItemStyle>
        ))}
      </KidsTextContainer>

      <Gallery collectionName="KidsBottomTextGallery" />
    </KidsContent>
  );
}

export default PresentationKids;
