import styled from "styled-components";

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    `;

export const LoadingText = styled.h1`
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-left: 10px;
    `;