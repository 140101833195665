import React, { useEffect, useState } from "react";
import PresentationGrappling from "../Components/Presentations/PresentationGrappling";
import Footer from "../Components/Footer/Footer";
import InstructorCardContainer from "../Components/InstructorCards/GrapplingInstructorCards";
import CircularProgress from "@mui/material/CircularProgress";

// Importa db y Firestore
import { db } from "../Firebase"; // Asegúrate de que esta ruta sea la correcta
import { collection, doc, getDocs } from "firebase/firestore";

function Grappling() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrapplingData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      );
      // Referencia a la colección anidada "Grappling"
      const grapplingCollectionRef = collection(
        landingConfigDocRef,
        "Grappling"
      );

      const grapplingSnapshot = await getDocs(grapplingCollectionRef);
      const grapplingList = grapplingSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordena el array basado en el campo "order"
      const sortedGrapplingList = grapplingList.sort(
        (a, b) => a.order - b.order
      );
      setData(sortedGrapplingList);
      setLoading(false);
    };

    fetchGrapplingData();
  }, []);

  return (
    <>
      <PresentationGrappling />
      {loading ? (
        <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <InstructorCardContainer grapplingData={data} />
      )}
      <Footer />
    </>
  );
}

export default Grappling;
