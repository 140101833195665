import styled from "styled-components";

export const WhiteInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 20px;
  height: auto;
  text-align: center;
  position: relative;
`;

export const WhiteInfoTitle = styled.h1`
  margin-top: 40px;
  font-size: 48px;
  font-style: normal;
  color: black;
  font-weight: 400;
  text-align: center;
`;

export const WhiteInfoParagraph = styled.p`
  font-size: 20px;
  color: #333;
`;

export const FlexboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const FlexboxParagraph = styled.p`
  &::after {
    content: "";
    display: block;
    height: 1px;
    margin-top: 10px;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  padding: 10px;
  width: 100%;
  margin-right: 5%;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  padding: 10px;
  width: 100%;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
