import styled from "styled-components";

export const ImageWithFixedHeight = styled.img`
  height: ${({ fixedHeight }) => fixedHeight}px;
  width: auto;
  margin-right: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  color: white;
  position: relative;
  min-height: 100vh;


`;

export const TextContainer = styled.div`
  flex: 2;
  margin: 150px;
  padding: 20px;
  position: relative;
  z-index: 2;



  @media (max-width: 950px) {
    position: absolute; // Hace que el contenedor del texto tenga posición absoluta en móviles
    top: 50%; // Centra verticalmente en móviles
    left: 50%; // Centra horizontalmente en móviles
    transform: translate(
      -50%,
      -50%
    ); // Ajuste final para centrar exactamente en la mitad
    margin: 0; // Quita márgenes en móviles
    width: 90%; // Toma el 90% del ancho de la vista del dispositivo
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  position: absolute;
  top: 0;
  right: 0; // Alinea el contenedor a la derecha
  bottom: 0;
  z-index: 1;
  overflow: hidden; // Oculta el contenido que se sale del contenedor

  @media (max-width: 950px) {
    width: 110%; // Ocupa el 75% del ancho en dispositivos móviles
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%; // Adjusted to cover the full height
  object-fit: cover;
  object-position: right center; // Centers the image vertically
  filter: blur(1.5px);
  opacity: 0.7; 

  @media (max-width: 950px) {
    height: 100%; // Mantiene la proporción de la imagen
    width: 110%; // Asegura que la imagen cubra todo el ancho
    object-fit: cover; // Asegura que la imagen cubra el área disponible
    object-position: center; // Centra la imagen para mostrar la parte más relevante
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;

  @media (max-width: 950px) {
    display: none;
  }
`;

export const StyledImage = styled(ImageWithFixedHeight)`
  margin-right: 10px;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 1.1;
  font-weight: 700;
`;

export const TitleParagraph = styled.p`
  margin: 0;
  font-size: 120px;

  @media (max-width: 950px) {
    font-size: 85px;
  }

  @media (max-width: 600px) {
    font-size: 80px;
  }

  @media (max-width: 600px) {
    font-size: 60px;
  }

  @media (max-width: 400px) {
    font-size: 40px;
  }
`;

export const Subtext = styled.div`
  font-size: 40px;
  margin-top: 10px;
  font-family: "Asap Condensed", sans-serif;
  font-style: italic;

  @media (max-width: 950px) {
    font-size: 24px;
    width: 60%;
  }
`;

export const LogoImage = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 120px;
  }
`;

export const RedText = styled.span`
  color: red;
`;
