import React, { useEffect, useState } from "react";
import PresentationStriking from "../Components/Presentations/PresentationStriking";
import Footer from "../Components/Footer/Footer";
import StrikingInstructors from "../Components/InstructorCards/StrikingInstructors";
import CircularProgress from "@mui/material/CircularProgress";

// Importa db y Firestore
import { db } from "../Firebase"; // Asegúrate de que esta ruta sea la correcta
import { collection, doc, getDocs } from "firebase/firestore";

function Striking() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStrikingData = async () => {
      // Referencia al documento específico en "LandingConfiguration"
      const landingConfigDocRef = doc(
        db,
        "LandingConfiguration",
        "x3aWKMi87yl6nMO0RtLh"
      );
      // Referencia a la colección anidada "Striking"
      const strikingCollectionRef = collection(
        landingConfigDocRef,
        "Striking"
      );

      const strikingSnapshot = await getDocs(strikingCollectionRef);
      const strikingList = strikingSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordena el array basado en el campo "order"
      const sortedStrikingList = strikingList.sort(
        (a, b) => a.order - b.order
      );
      setData(sortedStrikingList);
      setLoading(false);
    };

    fetchStrikingData();
  }, []);

  return (
    <>
      <PresentationStriking />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <StrikingInstructors strikingData={data} />
      )}
      <Footer />
    </>
  );
}

export default Striking;
