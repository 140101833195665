import styled from "styled-components";

export const GraduationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;  // Ajusta el ancho según tus necesidades
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: 20px;

  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }
`;

export const GraduationImage = styled.img`
  width: 100%;
  height: auto;
`;
