import React, { useState, useEffect } from 'react';
import { format, eachHourOfInterval, setHours, startOfDay, parse, isSameDay } from 'date-fns';
import { DayContainer, DayRow, TimeBlock, TimeBracket, ClassBlock, EventItem , InstructorLine, ClassItem} from './DayViewStyles';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase';

// Utility function to parse class start and end times considering various string formats
const parseClassTime = (timeString, referenceDate) => {
  // Handle potential space and case variations in time strings, e.g., "7:00 PM" or "7:00pm"
  const cleanedTimeString = timeString.toLowerCase().replace(/\s/g, ''); // Normalize time string
  // Parse the time string into a Date object for the given reference date
  return parse(cleanedTimeString, 'h:mma', referenceDate);
};


const DayView = ({ date, events, classes }) => {
  const [instructorNames, setInstructorNames] = useState({});

  useEffect(() => {
    const uniqueInstructorIds = new Set(classes.flatMap(cls => {
      return Object.values(cls.schedule)
        .filter(schedule => schedule.instructorUID)
        .map(schedule => schedule.instructorUID);
    }));

    const fetchInstructorNames = async () => {
      let namesMapping = {};
      for (let instructorId of uniqueInstructorIds) {
        const docRef = doc(db, "users", instructorId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const { firstname, lastname } = docSnap.data();
          namesMapping[instructorId] = `${firstname} ${lastname}`;
        } else {
          console.log(`No such document for instructorId: ${instructorId}`);
          namesMapping[instructorId] = "Unknown Instructor";
        }
      }
      setInstructorNames(namesMapping);
    };

    fetchInstructorNames();
  }, [classes]);
  // Define the time range for the DayView
  const startTimeRange = setHours(startOfDay(date), 6);
  const endTimeRange = setHours(startOfDay(date), 21);

  // Generate hourly intervals within the specified time range
  const hourlyIntervals = eachHourOfInterval({ start: startTimeRange, end: endTimeRange });

    // Function to format the Firestore Timestamp
    const formatFirestoreTimestamp = (timestamp) => {
      return format(timestamp.toDate(), 'h:mm a');
    };

const handleClassClick = async (cls) => {
  const weekday = format(date, 'EEEE').toLowerCase();
  const schedule = cls.schedule[weekday];
  if (schedule) {
    const startTime = format(parseClassTime(schedule.startTime, date), 'h:mm a');
    const endTime = format(parseClassTime(schedule.endTime, date), 'h:mm a');
    const instructorUID = schedule.instructorUID;
    let instructorName = 'Unknown Instructor';

    if (instructorUID) {
      instructorName = instructorNames[instructorUID];
    }

    alert(`${cls.className}\nInstructor: ${instructorName}\nTime: ${startTime} - ${endTime}`);
  } else {
    alert(`${cls.className}, Instructor: Unknown`);
  }
};


  return (
      <DayContainer>
        <h2>{format(date, 'eeee, MMMM do')}</h2>
        {hourlyIntervals.map((hour, index) => {
          const formattedHour = format(hour, 'h:mm a');
          const matchingClasses = classes.filter(cls => {
            const dayOfWeek = format(date, 'EEEE').toLowerCase();
            const schedule = cls.schedule[dayOfWeek];
            // Add visibility and start time checks for classes
            return schedule && schedule.isVisible && schedule.startTime && format(hour, 'ha') === format(parseClassTime(schedule.startTime, date), 'ha');
          });
          const matchingEvents = events.filter(event => {
            const eventStartDate = event.startTime.toDate ? event.startTime.toDate() : event.startTime;
            // Add visibility check for events
            return isSameDay(eventStartDate, date) && format(hour, 'ha') === format(eventStartDate, 'ha') && (event.onlyShowTo.length === 0 || event.onlyShowTo === null);
          });

          return (
            <DayRow key={index}>
              <TimeBlock>{formattedHour}</TimeBlock>
              <ClassBlock>
                {matchingClasses.map((cls, idx) => {
                  const weekday = format(date, 'EEEE').toLowerCase();
                  const instructorUID = instructorNames[cls.schedule[weekday]?.instructorUID] || 'N/A';

                  return (
                    <ClassItem key={idx} onClick={() => handleClassClick(cls)}>
                      {cls.className}
                      <InstructorLine>{instructorUID}</InstructorLine>
                    </ClassItem>
                  );
                })}
                {matchingEvents.map((event, idx) => {
                  const startTime = formatFirestoreTimestamp(event.startTime);
                  const endTime = event.endTime ? formatFirestoreTimestamp(event.endTime) : 'Unknown';
                  return (
                    <EventItem key={idx} onClick={() => alert(`${event.title}\nTime: ${startTime} - ${endTime}`)}>
                      {event.title} (Event)
                    </EventItem>
                  );
                })}
              </ClassBlock>
            </DayRow>
          );
        })}
      </DayContainer>
    );
  };

export default DayView;
