import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InstructorCard from "./InstructorCards";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%; // En móviles, el ancho será del 100%
    padding: 10px; // En móviles, el padding será de 10px
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;

function chunkArray(array, chunkSize) {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }
  return results;
}

function InstructorCardContainer({ grapplingData }) {
  const [cardsPerRow, setCardsPerRow] = useState(3);

  useEffect(() => {
    const updateCardsPerRow = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setCardsPerRow(1); // En pantallas pequeñas, muestra 1 tarjeta por fila
      } else if (width <= 1024) {
        setCardsPerRow(2); // En pantallas medianas, muestra 2 tarjetas por fila
      } else {
        setCardsPerRow(3); // En pantallas grandes, muestra 3 tarjetas por fila
      }
    };

    window.addEventListener("resize", updateCardsPerRow);
    updateCardsPerRow();

    return () => window.removeEventListener("resize", updateCardsPerRow);
  }, []);

  const activeInstructors = grapplingData.filter(
    (instructor) => instructor.isActive
  );
  const rows = chunkArray([...activeInstructors], cardsPerRow);

  return (
    <CardContainer>
      {rows.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.map((instructor, index) => (
            <InstructorCard
              key={rowIndex * cardsPerRow + index} // Un índice único
              name={instructor.title}
              imageSrc={instructor.image}
              text={instructor.description}
              subtitle={instructor.subtitle}
            />
          ))}
        </Row>
      ))}
    </CardContainer>
  );
}

export default InstructorCardContainer;
