import styled from 'styled-components';

export const ViewButton = styled.button`
  background-color: ${(props) => (props.selected ? 'red' : 'white')}; /* Red if selected, light otherwise */
  color: ${(props) => (props.selected ? 'white' : 'black')}; /* Text color white if selected, black otherwise */
  padding: 10px; /* Adjusted padding */
  margin: 0; /* Remove margin to ensure buttons are joined */
  border: none; /* Remove border for buttons */
  cursor: pointer;
  border-radius: ${(props) =>
    props.position === 'left' ? '50px 0 0 50px' :
    props.position === 'right' ? '0 50px 50px 0' : '0'};
  &:hover {
    background-color: ;
    color: white;
  }
  flex-grow: 1; /* Ensure buttons take up equal space */
  flex-basis: 0; /* Ensure buttons start with 0 width before growing */
`;

export const CenteredContainer = styled.div`
  /* Apply necessary styling for the calendar component */
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the children horizontally */
  justify-content: start; /* Align children to the top */
  width: 100%; /* Take full width to ensure centering within the parent */
  /* Add other styles as needed */
`;

export const ButtonGroup = styled.div`
    align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50px; /* Pill shape */
  overflow: hidden; /* Ensures inner buttons conform to pill shape */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Some shadow for depth */
  margin-bottom: 20px;
  width: 200px; /* Set the total width of the button group */
`;

// Style for navigation buttons (previous, next, today)
export const NavButton = styled.button`
  background-color: red; /* Blue background */
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #f9f9f9;
    color: black;
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  background-color: #FF4136; /* Red background for the toggle */
  border-radius: 50px; /* Pill shape */
  overflow: hidden; /* Ensures inner buttons conform to pill shape */
  margin-bottom: 20px;
  width: 200px; /* Set the same width as the ButtonGroup below */
`;


export const ToggleButton = styled.button`
  background-color: ${(props) => (props.selected ? 'red' : 'white')}; /* Red if selected, transparent otherwise */
  color: ${(props) => (props.selected ? 'white' : 'black')}; /* Text color white for better contrast */
  padding: 10px 20px; /* Adjust padding for spacing */
  border: none; /* No border for toggle buttons */
  cursor: pointer;
  &:hover {
    background-color: #FF4136; /* Slight opacity change on hover */
  }
  flex: 1; /* Ensure buttons take up equal space */
`;

export const NavigationButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 200px;
`;

export const NavigationButton = styled.button`
  background-color: white;
  color: black;
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #FF4136;
    color: white;
  }

  // Arrow shape adjustments
  &:first-child {
    clip-path: polygon(0 50%, 20% 0, 100% 0, 100% 100%, 20% 100%);
    border-radius: 5px;
  }
  &:nth-child(2) {
    border-radius: 5px; // Keep "Today" button rounded
  }
  &:last-child {
    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
    border-radius: 5px;
  }
`;

