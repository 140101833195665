import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarContainer = styled.div`
  background: rgba(51, 51, 51, 0.6);
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(10px);

  .navbar-top {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }

  .navbar-brand-mobile {
    display: none;
    font-size: 24px;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .mobile-menu-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }

  .mobile-menu-items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    background: rgba(51, 51, 51, 0.9);
    &.active {
      display: flex;
    }
  }

  .desktop-menu-items {
    display: flex;
  }

  @media screen and (max-width: 950px) {
    padding: 30px 0; // Aumentar el relleno para dispositivos móviles

    .navbar-top {
      display: flex;
    }

    .navbar-brand-mobile {
      display: block;
    }

    .mobile-menu-icon {
      display: block;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .desktop-menu-items {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  background-color: transparent;
  color: white;
  border-radius: 8px;
  border: 0;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  font-family: "Alatsi", sans-serif;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #333;
  }
`;

export const RedButton = styled(Link)`
  background-color: Red;
  color: white;
  border-radius: 8px;
  border: 0;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  font-family: "Alatsi", sans-serif;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #ff6666;
  }
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  color: white;
  border-radius: 8px;
  border: 0;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  font-family: "Alatsi", sans-serif;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #333;
  }

  &::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    transform: translateY(-50%);
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: rgba(51, 51, 51, 1);
  min-width: 160px;
  z-index: 101;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownContent} {
    display: block;
  }
`;

export const DropdownItem = styled(StyledLink)`
  display: block;
  padding: 10px;
  color: white;

  &:hover {
    background-color: rgba(51, 51, 51, 2);
  }
`;
